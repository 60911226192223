<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1>{{'checkout_headertitle' | translate}}</h1>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li><a href="index.html">Home</a></li>
          <li><a>{{'risultati_headertitle' | translate}}</a></li>
          <li><a class="cursor-pointer" onclick="history.back();">{{'checkout_headertitle' | translate}}</a></li>
          <li class="active">{{'pagamento_headertitle' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Page Header end -->
 <!-- Content
  ============================================= -->
  <div id="content">
    <div class="container">
      <div class="bg-white shadow-md rounded p-4">
        <h3 class="text-6 mb-4">{{'pagamento_selezionamodalitapagamento' | translate}}</h3>
		<hr class="mx-n4">
        <div class="row">
          <div class="col-md-7 col-lg-8 order-1 order-md-0">
<tabset>
            <div class="tab-content my-3" id="myTabContent">

            <tab heading="{{'pagamento_cartecreditodebito' | translate}}">
              <!-- Cards Details
              ============================================= -->
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="text-4 mb-4">{{'pagamento_dettaglicartacredito' | translate}}</h3>
                    <form id="payment" method="post">
                      <div class="form-group">
                        <label for="cardNumber">{{'pagamento_cartacreditonumbero' | translate}}</label>
                        <input type="text" class="form-control" data-bv-field="cardnumber" id="cardNumber" required placeholder="{{'pagamento_numerocarta' | translate}}">
                      </div>
                      <div class="form-row">
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="expiryMonth">{{'pagamento_mese' | translate}}</label>
                            <select id="expiryMonth" class="custom-select" required="">
                              <option value="">{{'pagamento_mese' | translate}}</option>
                              <option>January</option>
                              <option>February</option>
                              <option>March</option>
                              <option>April</option>
                              <option>May</option>
                              <option>June</option>
                              <option>July</option>
                              <option>August</option>
                              <option>September</option>
                              <option>October</option>
                              <option>November</option>
                              <option>December</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="expiryYear">{{'pagamento_anno' | translate}}</label>
                            <select id="expiryYear" class="custom-select" required="">
                              <option value="">{{'pagamento_anno' | translate}}</option>
                              <option>2018</option>
                              <option>2019</option>
                              <option>2020</option>
                              <option>2021</option>
                              <option>2022</option>
                              <option>2023</option>
                              <option>2024</option>
                              <option>2025</option>
                              <option>2026</option>
                              <option>2027</option>
                              <option>2028</option>
                              <option>2029</option>
                              <option>2030</option>
                              <option>2031</option>
                              <option>2032</option>
                              <option>2033</option>
                              <option>2034</option>
                              <option>2035</option>
                              <option>2036</option>
                              <option>2037</option>
                              <option>2038</option>
                              <option>2039</option>
                              <option>2040</option>
                              <option>2041</option>
                              <option>2042</option>
                              <option>2043</option>
                              <option>2044</option>
                              <option>2045</option>
                              <option>2046</option>
                              <option>2047</option>
                              <option>2048</option>
                              <option>2049</option>
                              <option>2050</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="cvvNumber">CVV</label>
                            <input type="text" class="form-control" data-bv-field="cvvnumber" id="cvvNumber" required placeholder="CVV">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="cardHolderName">{{'pagamento_intestatariocarta' | translate}}</label>
                        <input type="text" class="form-control" data-bv-field="cardholdername" id="cardHolderName" required placeholder="{{'pagamento_intestatariocarta' | translate}}">
                      </div>
                      <!--
                      <div class="form-group custom-control custom-checkbox">
                        <input id="save-card" name="savecard" class="custom-control-input" type="checkbox">
                        <label class="custom-control-label" for="save-card">Save my card Details.</label>
                      </div>
                      -->
                      <button class="btn btn-primary btn-block" type="submit">{{'pagamento_confermaepaga' | translate}}</button>
                    </form>
                  </div>
                </div>
                <!-- Cards Details end -->
              </tab>
              <tab heading="PayPal">
              <!-- Pay via Paypal
            ============================================= -->
              <form>
                <img class="img-fluid" src="assets/images/paypal-logo.png" alt="Paypal Logo" title="{{'pagamento_paypalslogan' | translate}}">
                <p class="lead">{{'pagamento_paypalslogan' | translate}}</p>
                <p class="text-info mb-4"><i class="fa fa-info-circle"></i> {{'pagamento_paypalredirect' | translate}}.</p>
                <button class="btn btn-primary btn-block" type="submit"><i class="fa fa-paypal fa-2x mr-2"></i>{{'pagamento_pagaconpaypal' | translate}}</button>
              </form>
              <!-- Pay via Paypal end -->
             </tab>
            </div>
          </tabset>
          </div>
          <div class="col-md-5 col-lg-4 order-0 order-md-1">

            <!-- Payable Amount
          ============================================= -->
            <div class="bg-light-2 rounded p-4 mb-4">
              <div class="text-dark text-4 font-weight-500 py-1"> {{'pagamento_importototale' | translate}}<span class="float-right text-7">€ {{biglietto.viaggioRitorno?biglietto.viaggioAndata.prezzoTotale+biglietto.viaggioRitorno.prezzoTotale:biglietto.viaggioAndata.prezzoTotale}}</span></div>
            </div>
            <!-- Payable Amount end -->

            <!-- Privacy Information
			============================================= -->
            <div class="bg-light-2 rounded p-4 d-none d-md-block">
			  <h3 class="text-4 mb-3">{{'pagamento_privacy' | translate}}</h3>
              <p class="mb-0">We will not sell or distribute your contact information. Read our <a href="#">Privacy Policy</a>.</p>
              <hr class="mx-n4">
			  <h3 class="text-4 mb-3">{{'pagamento_richiestefatture' | translate}}</h3>
              <p class="mb-0">Do not hesitate to reach our <a href="#">support team</a> if you have any queries.</p>
            </div>
            <!-- Privacy Information end -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content end -->
