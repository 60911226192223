import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgxSpinnerService } from 'ngx-spinner';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  langs = ['it', 'en'];
  title = 'La Valle Interlines';

  constructor(public router: Router,private translateService:TranslateService,private spinner: NgxSpinnerService,private ccService: NgcCookieConsentService) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('config', 'UA-72588901-3',
              {
                'page_path': event.urlAfterRedirects
              }
          );
       }
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    let browserLang = this.translateService.getBrowserLang();
    if (this.langs.indexOf(browserLang) > -1) {
      this.translateService.setDefaultLang(browserLang);
      this.translateService.use(browserLang);
    } else {
      this.translateService.setDefaultLang('it');
      this.translateService.use('it');
    }
    //TRADUCE MESSAGGI COOKIE POPUP
    this.translateService
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });

    this.spinner.hide();

  }

}
