<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1>{{'passworddimenticata_headertitle' | translate}}</h1>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li><a href="index.html">Home</a></li>
          <li class="active">{{'passworddimenticata_headertitle' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Page Header end -->
<!-- Content
  ============================================= -->
  <div id="content">
    <div class="container pt-5 pb-4">
        <div class="row">
          <div class="col-md-9 col-lg-7 col-xl-5 mx-auto">
            <div class="bg-white shadow-md rounded p-3 pt-sm-4 pb-sm-5 px-sm-5">
              <h3 class="text-center mt-3 mb-4">{{'passworddimenticata_headertitle' | translate}}?</h3>
              <p class="text-center text-3 text-muted">{{'passworddimenticata_inseriscilamailreset' | translate}}</p>
              <form class="form-border" (ngSubmit)="ngformreset.valid && resetPassword()"  #ngformreset=ngForm>
                <div class="form-group">
                  <input type="text" class="form-control border-2" name="emailAddress" [(ngModel)]="email" required placeholder="{{'passworddimenticata_inseriscilamail' | translate}}">
                </div>
                <div class="form-group text-center bg-primary p-1 line-height-1" *ngIf="resettato===-1" >
                  <span class="text-1 text-light">{{'passworddimenticata_recuperopasswordko' | translate}}</span>
                </div>
                <div class="form-group text-center bg-primary p-1 line-height-1" *ngIf="resettato===-2" >
                  <span class="text-1 text-light">{{'passworddimenticata_mailinesistente' | translate}}</span>
                </div>
                <div class="form-group text-center bg-success p-1 line-height-1" *ngIf="resettato===1" >
                  <span class="text-1 text-light">{{'passworddimenticata_passwordresettata' | translate}}</span>
                </div>
                <button class="btn btn-primary btn-block my-4" [disabled]="!ngformreset.valid">{{'generic_continua' | translate }}</button>
              </form>
              <p class="text-center mb-0"><a class="btn-link" [routerLink]="['/home']">{{'passworddimenticata_vaiallahome' | translate }}</a></p>
        </div>
      </div>
      </div>
    </div>

    </div><!-- Content end -->
