<!-- Content
  ============================================= -->
  <div id="content">
    <div class="container">
      <div class="row my-5">

	  <div class="col-md-11 mx-auto">
      <div class="col-lg-12 text-center mt-5" *ngIf="valid===0">
        <p class="text-24 line-height-07"  ><i class="fa fa-address-card"></i></p>
        <h2 class="text-8">{{'confirmregistration_convalidaregistrazionecorso' | translate}}</h2>
      </div>
      <div class="col-lg-12 text-center mt-5" *ngIf="valid===1">
        <p class="text-success text-24 line-height-07"  ><i class="fa fa-check-circle"></i></p>
        <h2 class="text-8">{{'confirmregistration_emailconfermata' | translate}}</h2>
        <a [routerLink]="['/home']" class="btn btn-primary shadow-none px-5 m-2">{{'prenotazioneexpired_acquistadinuovo' | translate}}</a>
      </div>
      <div class="col-lg-12 text-center mt-5" *ngIf="valid===-1">
        <p class="text-danger text-24 line-height-07"  ><i class="fa fa-exclamation-circle"></i></p>
        <h2 class="text-8">{{'confirmregistration_emailnonconfermata' | translate}}</h2>
      </div>

    </div>
  </div><!-- Content end -->
