import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.css']
})
export class ConfirmRegistrationComponent implements OnInit {

  email:string ='';
  id:number = 0;
  verificationCode:string = '';
  valid: number = 0;

  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private profileService: ProfileService, private translateService:TranslateService) {
    this.email=this.route.snapshot.queryParamMap.get('email');
    this.id=+this.route.snapshot.queryParamMap.get('id');
    this.verificationCode=this.route.snapshot.queryParamMap.get('verificationCode');
  }

  ngOnInit(): void {
    this.valid = 0;
    this.spinner.show();
    this.profileService.confermaRegistrazione(this.email,this.id,this.verificationCode).subscribe(
      resp =>{
        if(resp.response==='ok') this.valid=1;
        else this.valid=-1;
        this.spinner.hide();
      },
      err =>{
        this.valid = -1;
        this.spinner.hide();
      }
    );
  }

}
