import { Biglietto } from './../models/biglietto';
import { Viaggio } from './../models/viaggio';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CorseService } from '../services/corse.service';
import { Options } from '@angular-slider/ngx-slider';
import { TranslateService } from '@ngx-translate/core';
import { UtilsHelperService } from '../services/utils-helper.service';
import { CollapseDetailViaggio } from '../models/collapseInfoViaggio';
import { Passeggero } from '../models/passeggero';

@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.css']
})

export class SearchresultsComponent implements OnInit {
  errorTimeout: boolean = false;

  dateFormat:string ="dd/MM/yyyy";
  NUM_MAX_CAMBI=2;
  isCollapsedCambi = false; //DROPDOWN FILTRI
  isCollapsedPrezzo = false; //DROPDOWN FILTRI
  isCollapsedOrari = false; //DROPDOWN FILTRI
  isCollapsedInfoViaggioAndata: CollapseDetailViaggio[] = [];
  isCollapsedInfoViaggioRitorno: CollapseDetailViaggio[] = [];

  isAndataRitorno = false;
  cittaPartenza:string = '';
  cittaArrivo:string = '';
  codiceCittaPartenza:number = 0;
  codiceCittaArrivo:number = 0;
  dataPartenza:string = '';
  dataRitorno:string = '';
  numAdulti:number = 0;
  numBambini:number = 0;
  listaCorseAndata:Viaggio[];
  listaCorseRitorno:Viaggio[];
  biglietto:Biglietto = new Biglietto();

  filtroOraMin: number = 0;
  filtroOraMax: number = 24;
  filtriOrario: Options = {
    floor: 0,
    ceil: 24,
    showTicksValues: false,
    tickStep: 1,
    tickValueStep: 1,
    translate: (value: number): string => {
      if(value==24) return "23:59";
      return value+':00';
    }
  };

  filtroPrezzoMin: number = 0;
  filtroPrezzoMax: number = 500;
  filtriPrezzo: Options = {
    floor: 0,
    ceil: 500,
    showTicksValues: false,
    tickStep: 1,
    tickValueStep: 1,
    translate: (value: number): string => {
      return '€ '+value;
    }
  };

  filtroCambio = -1;

  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private corseService: CorseService, private translateService:TranslateService) {
    this.cittaPartenza=this.route.snapshot.queryParamMap.get('cittaPartenza');
    this.cittaArrivo=this.route.snapshot.queryParamMap.get('cittaArrivo');
    this.codiceCittaPartenza=+this.route.snapshot.queryParamMap.get('cittaPartenzaRef');
    this.codiceCittaArrivo=+this.route.snapshot.queryParamMap.get('cittaArrivoRef');
    this.dataPartenza=this.route.snapshot.queryParamMap.get('dataPartenza');
    this.dataRitorno=this.route.snapshot.queryParamMap.get('dataRitorno');
    this.numAdulti=+this.route.snapshot.queryParamMap.get('numAdulti');
    this.numBambini=+this.route.snapshot.queryParamMap.get('numBambini');
    this.filtroCambio=+this.route.snapshot.queryParamMap.get('numMaxCambi');
    if(this.filtroCambio<=0) this.filtroCambio=this.NUM_MAX_CAMBI;
    this.isAndataRitorno=this.dataRitorno!=null && this.dataRitorno!='';
  }

  ngOnInit(): void {
    this.biglietto=new Biglietto();
    for(let i=0;i<this.numAdulti;i++)
      this.biglietto.passeggeriAdulti.push(new Passeggero());
    for(let i=0;i<this.numBambini;i++)
      this.biglietto.passeggeriBambini.push(new Passeggero());

    this.dateFormat = UtilsHelperService.getLocaleDateStringToShow(this.translateService.currentLang);
    this.listaCorseAndata=[];
    this.isCollapsedInfoViaggioAndata=[];
    this.listaCorseRitorno=[];
    this.isCollapsedInfoViaggioRitorno=[];
    this.resetFiltri();
    this.cercaSoluzioni();
  }

  resetFiltri(){
    this.filtroOraMin = 0;
    this.filtroOraMax = 24;
    this.filtroPrezzoMin = 0;
    this.filtroPrezzoMax = 500;
    this.filtroCambio = -1;
  }

  cercaSoluzioni(){
    this.errorTimeout=false;
    this.spinner.show();
    //GESTIONE FILTRI
    let orarioMin='';
    if(this.filtroOraMin==24) orarioMin='23:59:00';
    else orarioMin=this.filtroOraMin+':00:00';

    let orarioMax='';
    if(this.filtroOraMax==24) orarioMax='23:59:00';
    else orarioMax=this.filtroOraMax+':00:00';

    if(this.filtroCambio==-1 || this.filtroCambio==3) this.filtroCambio=this.NUM_MAX_CAMBI;
    //-----------------------
    if(this.biglietto.viaggioAndata==null){
      this.corseService.elencoCorse(this.codiceCittaPartenza,this.codiceCittaArrivo,this.dataPartenza,this.numAdulti,this.numBambini,this.filtroCambio,''+orarioMin,''+orarioMax,this.filtroPrezzoMin,this.filtroPrezzoMax).subscribe(
        response => {
          this.errorTimeout=false;
          this.listaCorseAndata=response.listaViaggi;
          for(let i=0;i<this.listaCorseAndata.length;i++){
            this.isCollapsedInfoViaggioAndata[i]=new CollapseDetailViaggio();
            this.isCollapsedInfoViaggioAndata[i].isCollapsed = true;
            for(let j=0;j<=this.listaCorseAndata[i].tratte.length;j++){
              this.isCollapsedInfoViaggioAndata[i].tratte[j] = true;
            }
          }
          this.spinner.hide();
        },
        error =>{
          this.spinner.hide();
          this.listaCorseAndata=[];
          this.isCollapsedInfoViaggioAndata=[];
          console.log(error);
          this.errorTimeout=true;
        }
      )
    }
    else if(this.biglietto.viaggioAndata!=null && this.isAndataRitorno){
      this.corseService.elencoCorse(this.codiceCittaArrivo,this.codiceCittaPartenza,this.dataRitorno,this.numAdulti,this.numBambini,this.filtroCambio,''+orarioMin,''+orarioMax,this.filtroPrezzoMin,this.filtroPrezzoMax).subscribe(
        response => {
          this.errorTimeout=false;
          this.listaCorseRitorno=response.listaViaggi;
          for(let i=0;i<this.listaCorseRitorno.length;i++){
            this.isCollapsedInfoViaggioRitorno[i]=new CollapseDetailViaggio();
            this.isCollapsedInfoViaggioRitorno[i].isCollapsed = true;
            for(let j=0;j<=this.listaCorseRitorno[i].tratte.length;j++){
              this.isCollapsedInfoViaggioRitorno[i].tratte[j] = true;
            }
          }
          this.spinner.hide();
        },
        error =>{
          this.spinner.hide();
          this.listaCorseRitorno=[];
          this.isCollapsedInfoViaggioRitorno=[];
          console.log(error);
          this.errorTimeout=true;
        }
      )
    }
  }

  selezionaViaggio(t:number,corsa:Viaggio){
    if(t==1){ //ANDATA
      this.listaCorseAndata=[];
      this.biglietto.viaggioAndata=corsa;
      if(this.isAndataRitorno) this.cercaSoluzioni();
      else {
        this.aggiungiCarrello(this.biglietto);
      }
    }
    else{
      this.biglietto.viaggioRitorno=corsa;
      this.aggiungiCarrello(this.biglietto);
    }
  }
  rimuoviViaggio(t:number){
    if(t==1){ //ANDATA
      this.biglietto.viaggioAndata=null;
      this.cercaSoluzioni();
    }
    else{
      this.biglietto.viaggioRitorno=null;
      if(this.isAndataRitorno) this.cercaSoluzioni();
    }
  }

  aggiungiCarrello(biglietto:Biglietto){
    this.svuotaCarrello();
    this.impegnoBiglietto(biglietto);
  }

  svuotaCarrello(){
    let cartId=this.corseService.getLocalCart("cart_id");
    this.corseService.svuotaLocalCart();
    if(cartId){
      this.disimpegnoBiglietto(cartId);
    };
  }

  impegnoBiglietto(biglietto:Biglietto){
    this.spinner.show();
    this.corseService.impegnoBiglietto(JSON.stringify(biglietto)).subscribe(
      response =>{
        this.spinner.hide();
        if(response.errore.codice==2){
          //IL VIAGGIO NON E' PIU' DISPONIBILE
          this.router.navigate(['/booking-expired']);
          return;
        }
        this.corseService.addLocalCart("cart",JSON.stringify(biglietto));
        this.corseService.addLocalCart("cart_id",response.codiceCarrello);
        this.corseService.addLocalCart("cart_expire",""+response.scadenzaCarrello);
        this.router.navigate(['checkout'],{state: {biglietto: this.biglietto}});
      },
      error =>{
        this.spinner.hide();
        this.router.navigate(['/service-unavailable']);
        return;
      }
    )
  }

  disimpegnoBiglietto(cartId:string){
    this.corseService.disimpegnoBiglietto(cartId).subscribe(
      response =>{
      }
    )
  }

}
