import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-password-dimenticata',
  templateUrl: './password-dimenticata.component.html',
  styleUrls: ['./password-dimenticata.component.css']
})
export class PasswordDimenticataComponent implements OnInit {

  email:string = '';
  resettato:number = 0;

  constructor(private spinner: NgxSpinnerService,private profileService:ProfileService) {

  }

  ngOnInit(): void {

  }

  resetPassword(){
    this.resettato = 0;
    this.spinner.show();
    this.profileService.resetPassword(this.email).subscribe(
      resp =>{
        this.spinner.hide();
        if(resp.errore.codice==5){ //EMAIL INESISTENTE
          this.resettato=-2;
          return;
        }
        if(resp.response==='ok') this.resettato=1;
        else this.resettato=-1
      },
      err => {
        this.spinner.hide();
        this.resettato=-1;
      }
    );
  }

}
