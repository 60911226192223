import { PostoBus } from './../models/postoBus';
import { Biglietto } from './../models/biglietto';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CorseService } from '../services/corse.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { UserInfo } from '../models/userInfo';
import { Tratta } from '../models/tratta';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  bsModalRef: BsModalRef;
  biglietto:Biglietto;

  //variabili per mappa
  trattaSelezionata:Tratta;
  postiAssegnati:string[]=[];
  postiBloccati:string[]=[];
  numeroPiani: number;
  matriceposti_piano0:any[]= [];
  matriceposti_piano1:any[]= [];
  //
  errorImpegnoPosti:boolean = false;

  LoginStatus$Sottoscrizione : Subscription;
  LoginStatus$ : Observable<UserInfo>;
  userInfo : UserInfo = new UserInfo();


  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private corseService: CorseService,public authService: AuthService, private translateService:TranslateService,private modalService: BsModalService) {
    if(this.corseService.getLocalCart("cart")){
      this.biglietto=JSON.parse(this.corseService.getLocalCart("cart"));
      let cartId=this.corseService.getLocalCart("cart_id");
      this.spinner.show();
      this.corseService.controlloValiditaCarrello(this.corseService.getLocalCart("cart"),cartId,false).subscribe(
        valid => {
          this.spinner.hide();
          if(!valid) this.router.navigate(['/booking-expired']);
        },
        (err) =>{
          this.spinner.hide();
          this.router.navigate(['/service-unavailable']);
        }
      );
    }
  }

  ngOnInit(): void {
    if(!this.biglietto || (!this.biglietto.viaggioAndata && !this.biglietto.viaggioRitorno))
      this.router.navigate(['/booking-expired']);
    this.LoginStatus$ = this.authService.isLoggedIn;
    this.LoginStatus$Sottoscrizione=this.LoginStatus$.subscribe((user) => {
      if(!user) user=new UserInfo();
      this.userInfo=user;

      this.biglietto.emailContatto=this.biglietto.emailContatto===''?this.userInfo.email:this.biglietto.emailContatto;
      this.biglietto.telefonoContatto=this.biglietto.telefonoContatto===''?this.userInfo.telefono:this.biglietto.telefonoContatto;
      this.biglietto.passeggeriAdulti[0].cognome=this.biglietto.passeggeriAdulti[0].cognome===''?this.userInfo.cognome:this.biglietto.passeggeriAdulti[0].cognome;
      this.biglietto.passeggeriAdulti[0].nome=this.biglietto.passeggeriAdulti[0].nome===''?this.userInfo.nome:this.biglietto.passeggeriAdulti[0].nome;
      this.biglietto.passeggeriAdulti[0].eta=this.biglietto.passeggeriAdulti[0].eta===0 && this.userInfo.eta>0?this.userInfo.eta:this.biglietto.passeggeriAdulti[0].eta;
    });
  }

  ngOnDestroy(): void {
    this.LoginStatus$Sottoscrizione.unsubscribe();
  }

  public openLoginComponent(){
    const initialState = {
    };
    this.bsModalRef = this.modalService.show(LoginComponent, {initialState});
  }

  public openSelezionePosti(template: TemplateRef<any>,tratta:Tratta){
    this.trattaSelezionata=tratta;
    this.bsModalRef = this.modalService.show(template,Object.assign({}, { class: 'modal-lg' }));
    this.refreshPrenotazioniSuMappa(tratta);
  }

  refreshPrenotazioniSuMappa(tratta:Tratta){
    let cartId=this.corseService.getLocalCart("cart_id");
    this.postiAssegnati=[];
    this.postiBloccati=[];
    this.spinner.show();
    this.corseService.elencoPostiPrenotati(cartId,tratta.codiceCorsaWeb,tratta.codicePercorsoPartenza,tratta.codicePercorsoArrivo).subscribe(
      response => {
        this.spinner.hide();
        this.postiAssegnati=response.listaPostiPrenotati;
        this.postiBloccati=response.listaPostiBloccati;
        //CREO LA MAPPA BUS
        this.getMappaBus(tratta);
      },
      (err) => {
        this.spinner.hide();
      }
      );

  }

  getMappaBus(tratta:Tratta){
    this.matriceposti_piano0=[];
    this.matriceposti_piano1=[];
    this.spinner.show();
    this.corseService.getMappaBus(tratta.codiceCorsaWeb).subscribe(
      response => {
        this.spinner.hide();
        this.numeroPiani=response.mappa.length;
        for (var x = 0; x < response.mappa.length; x++) {
          //PER OGNI PIANO
          var listaFilePosti:any[]= [];
          var listaPosti:PostoBus[]=[];
          var numColonne:number=response.mappa[x].numColonne;
          var numRighe:number=response.mappa[x].numRighe;
          var numPiano:number=response.mappa[x].numPiano;
          for(var i=1;i<=90;i++){
            var jsonPosto:PostoBus = new PostoBus();
            var stato:number=0;
            var isBusiness:boolean=false;
            var posto:string=""+response.mappa[x]['col'+i];
            /*
            if(posto!=null && posto !=undefined && posto.endsWith("B"))
              isBusiness=true;
            if(this.postiAssegnati.indexOf(""+posto)>=0)
              stato=2;
            if(tratta.postiSelezionati.indexOf(""+posto)>=0)
                stato=1;
            else if(this.postiBloccati.indexOf(""+posto)>=0)
              stato=3;
*/
            jsonPosto.seat=posto;
            /*
            jsonPosto.stato=stato;
            jsonPosto.isBusiness=isBusiness;
            jsonPosto.isDisabledClick=posto=='C' || posto=='NA'  || posto=='' || stato==2 || stato==3;
*/
            listaPosti.push(jsonPosto);
            if(i%numColonne==0){
              listaFilePosti.push(listaPosti);
              listaPosti=[];
              if(listaFilePosti.length==numRighe) break;
            }
          }
          if(numPiano==0)
            this.matriceposti_piano0=listaFilePosti;
          else if(numPiano==1)
            this.matriceposti_piano1=listaFilePosti;
      }
      this.renderingMappaBus(tratta);
      //console.log(JSON.stringify(this.matriceposti_piano0));
      //console.log(JSON.stringify(this.matriceposti_piano1));
    }, (err) => {
      this.spinner.hide();
      console.log("ERRORE "+err);
    });
  }

  renderingMappaBus(tratta:Tratta){
    if(this.matriceposti_piano0){
      for (var x = 0; x < this.matriceposti_piano0.length; x++) {
        let filaPosti:any[]=this.matriceposti_piano0[x];
        for (var y = 0; y < filaPosti.length; y++) {
          let jsonPosto:PostoBus=filaPosti[y];

          jsonPosto.isBusiness=jsonPosto.seat!=null && jsonPosto.seat !=undefined && jsonPosto.seat.endsWith("B");
          if(tratta.postiSelezionati.indexOf(""+jsonPosto.seat)>=0)
            jsonPosto.stato=1;
          else if(this.postiAssegnati.indexOf(""+jsonPosto.seat)>=0)
            jsonPosto.stato=2;
          else if(this.postiBloccati.indexOf(""+jsonPosto.seat)>=0)
            jsonPosto.stato=3;
          else
            jsonPosto.stato=0;

          jsonPosto.isDisabledClick=jsonPosto.seat=='C' || jsonPosto.seat=='NA'  || jsonPosto.seat=='' || jsonPosto.stato==2 || jsonPosto.stato==3;
          filaPosti[y]=jsonPosto;
        }
      }
    }
    if(this.matriceposti_piano1){
      for (var x = 0; x < this.matriceposti_piano1.length; x++) {
        let filaPosti:any[]=this.matriceposti_piano1[x];
        for (var y = 0; y < filaPosti.length; y++) {
          let jsonPosto:PostoBus=filaPosti[y];

          jsonPosto.isBusiness=jsonPosto.seat!=null && jsonPosto.seat !=undefined && jsonPosto.seat.endsWith("B");
          if(this.postiAssegnati.indexOf(""+jsonPosto.seat)>=0)
            jsonPosto.stato=2;
          else if(tratta.postiSelezionati.indexOf(""+jsonPosto.seat)>=0)
            jsonPosto.stato=1;
          else if(this.postiBloccati.indexOf(""+jsonPosto.seat)>=0)
            jsonPosto.stato=3;
          else
            jsonPosto.stato=0;

          jsonPosto.isDisabledClick=jsonPosto.seat=='C' || jsonPosto.seat=='NA'  || jsonPosto.seat=='' || jsonPosto.stato==2 || jsonPosto.stato==3;
          filaPosti[y]=jsonPosto;
        }
      }
    }

  }

  getStyleClassSeat(posto){
    let style='seatCharts-cell';
    if(posto.seat=='C' || posto.seat=='NA'  || posto.seat=='') style+=' seatCharts-space';
    else{
      if(posto.stato==0) style+=' seatCharts-seat available first-class';
      else if(posto.stato==2) style+=' seatCharts-seat unavailable';
      else if(posto.stato==1) style+=' seatCharts-seat selected';
      else if(posto.stato==3) style+=' seatCharts-seat economy-class';
    }
    return style;
  }

  public selezionaPosto(posto:any){
    if(posto.isDisabledClick) return;
    this.errorImpegnoPosti=false;
    let indexPosto: number =this.trattaSelezionata.postiSelezionati.indexOf(posto.seat);
    if(indexPosto>=0)
      this.trattaSelezionata.postiSelezionati.splice(indexPosto, 1);
    else{
      if(this.trattaSelezionata.postiSelezionati.length<(this.biglietto.passeggeriAdulti.length+this.biglietto.passeggeriBambini.length))
        this.trattaSelezionata.postiSelezionati.push(posto.seat);
    }
    this.renderingMappaBus(this.trattaSelezionata);
  }
  public rimuoviPosto(posto:string){
    let indexPosto: number =this.trattaSelezionata.postiSelezionati.indexOf(posto);
    if(indexPosto>=0){
      this.trattaSelezionata.postiSelezionati.splice(indexPosto, 1);
      this.renderingMappaBus(this.trattaSelezionata);
    }
  }

  confermaSelezionePosti(){
    let cartId=this.corseService.getLocalCart("cart_id");
    this.corseService.impegnoPosti(JSON.stringify(this.trattaSelezionata.postiSelezionati),cartId,this.trattaSelezionata.codiceCorsaWeb,this.trattaSelezionata.codicePercorsoPartenza,this.trattaSelezionata.codicePercorsoArrivo).subscribe(
      resp=>{
        if(resp.errore.codice==0){
          this.corseService.addLocalCart("cart",JSON.stringify(this.biglietto));
          this.bsModalRef.hide();
          return;
        }
        else if(resp.errore.codice==3){
          this.errorImpegnoPosti=true;
          this.trattaSelezionata.postiSelezionati=[];
        }
        this.refreshPrenotazioniSuMappa(this.trattaSelezionata);
      }
    );
  }

  checkout(){
    this.router.navigate(['/pagamento']);
  }


}
