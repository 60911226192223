<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1>{{'novita_headertitle' | translate}}</h1>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li><a href="index.html">Home</a></li>
            <li><a class="pr-0" title="Novita ed Avvisi" [routerLink]="['/novita']">{{'novita_headertitle' | translate}}</a></li>
            <li class="active">{{'novita-single_headertitle' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Page Header end -->
  <!-- Content
  ============================================= -->
  <div id="content">
    <div class="container">
      <div class="row"> 
        
        <!-- Middle Panel
        ============================================= -->
        <div class="col-lg-8 col-xl-9">
          <div class="blog-post card shadow-sm border-0 mb-4 p-4">
            <h2 class="title-blog text-7">{{selectedNews.titolo}}</h2>
            <ul class="meta-blog mb-4">
              <li><i class="fa fa-calendar"></i>{{selectedNews.data}}</li>
              <li><i class="fa fa-file-archive-o"></i> {{selectedNews.categoria}}</li>
            </ul>
            <img class="img-fluid" [src]="selectedNews.urlfoto" alt="">
            <div class="card-body px-0 pb-0">
              <p [innerHTML]="selectedNews.descrizione"></p>
            </div>
            <hr class="pb-3">
            
            <!-- Tags & Share Social
            ================================= -->
            <!--
            <div class="row mb-3">
              <div class="col-lg-7 col-xl-8">
                <div class="tags text-center text-lg-left"> <a href="#">Tips</a> <a href="#">2021</a> <a href="#">Recharge</a> <a href="#">How To</a> <a href="#">Payment</a> </div>
              </div>
              <div class="col-lg-5 col-xl-4">
                <div class="d-flex flex-column">
                  <ul class="social-icons social-icons-colored justify-content-center justify-content-lg-end">
                    <li class="social-icons-facebook"><a data-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="" data-original-title="Facebook"><i class="fa fa-facebook-f"></i></a></li>
                    <li class="social-icons-twitter"><a data-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="" data-original-title="Twitter"><i class="fa fa-twitter"></i></a></li>
                    <li class="social-icons-google"><a data-toggle="tooltip" href="http://www.google.com/" target="_blank" title="" data-original-title="Google"><i class="fa fa-google"></i></a></li>
                    <li class="social-icons-linkedin"><a data-toggle="tooltip" href="http://www.linkedin.com/" target="_blank" title="" data-original-title="Linkedin"><i class="fa fa-linkedin"></i></a></li>
                    <li class="social-icons-instagram"><a data-toggle="tooltip" href="http://www.instagram.com/" target="_blank" title="" data-original-title="Instagram"><i class="fa fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          -->
            <!-- Author
			================================= -->
            
            <!-- Related Posts
            ================================= -->
            
            <!-- Comments
			================================= -->
            <!-- Write a Comment
			================================= -->
          </div>
        </div>
        <!-- Middle Panel End --> 
        
        <!-- Right Sidebar
        ============================================= -->
        <aside class="col-lg-4 col-xl-3"> 
          <!-- Search
		  =============================== -->
          <div class="input-group shadow-sm mb-4">
            <input class="form-control shadow-none border-0 pr-0" type="search" id="search-input" placeholder="Ricerca" [(ngModel)]="filtroDescrizione" name="filtroDescrizione">
            <div class="input-group-append"> <span class="input-group-text bg-white border-0 p-0">
              <button class="btn text-muted shadow-none px-3 border-0" type="button" (click)="onRefresh()"><i class="fa fa-search"></i></button>
              </span> </div>
          </div> 
      
          <!-- Categories
		  =============================== -->
          <div class="bg-white shadow-sm rounded p-3 mb-4">
            <h4 class="text-5">Categorie</h4>
            <hr class="mx-n3">
            <ul class="list-item" *ngFor="let categoria of listaNewsCategorie; let i = index">
              <li><a [routerLink]="" (click)="onRefreshCategoria(categoria.codice)">{{categoria.descrizione}}<span>({{categoria.numeronews}})</span></a></li>
            </ul>
          </div>
      
          <!-- Recent Posts
          =============================== -->
          <div class="bg-white shadow-sm rounded p-3 mb-4">
            <h4 class="text-5">{{'novita_ultimenovita' | translate}}</h4>
            <hr class="mx-n3">
            <div class="side-post">
              <div class="item-post" *ngFor="let newsrecent of listaNewsRecenti | slice:0:3; let i = index">
                <div class="img-thumb"><a [routerLink]="" (click)="onSelect(newsrecent)"><img class="rounded" [src]="listImage65x65[i]" title="" alt=""></a></div>
                <div class="caption"> <a [routerLink]="" (click)="onSelect(newsrecent)">{{newsrecent.titolo}}</a>
                  <p class="date-post">{{newsrecent.data}}</p>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Popular Tags
          =============================== -->
          <!--
          <div class="bg-white shadow-sm rounded p-3 mb-4">
            <h4 class="text-5">Popular Tags</h4>
            <hr class="mx-n3">
            <div class="tags"> <a href="#">Recharge</a> <a href="#">Tips</a> <a href="#">2021</a> <a href="#">Booking</a> <a href="#">Marketing</a> <a href="#">Bill</a> <a href="#">How To</a> <a href="#">Payment</a> <a href="#">Offers</a>  </div>
          </div>
        -->
        </aside>
        
        <!-- Right Sidebar End --> 
        
      </div>
    </div>
  </div>
  <!-- Content end --> 
