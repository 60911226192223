<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1>Cookie Policy</h1>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li><a href="index.html">Home</a></li>
            <li class="active">Cookie Policy</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Page Header end -->
  <!-- Content
  ============================================= -->
  <div id="content">
    <div class="container">
      <div class="bg-white shadow-md rounded p-4">
        <h2 class="text-6">COOKIES</h2>
        <h2 class="text-3">1 – Tipo di Cookies</h2>
        <p>1. Il sito lavalleviaggi.it utilizza cookies per rendere l’esperienza di navigazione dell’utente più facile ed intuitiva: i cookies sono piccole stringhe di testo utilizzate per memorizzare alcune informazioni che possono riguardare l’utente, le sue preferenze o il dispositivo di accesso a Internet (computer, tablet o cellulare) e vengono utilizzate principalmente per adeguare il funzionamento del sito alle aspettative dell’utente, offrendo un’esperienza di navigazione più personalizzata e memorizzando le scelte effettuate in precedenza.</p>
        <p>2. Un cookie consiste in un ridotto insieme di dati trasferiti al browser dell’utente da un server web e può essere letto unicamente dal server che ha effettuato il trasferimento. Non si tratta di codice eseguibile e non trasmette virus.</p>
        <p>3. I cookies non registrano alcuna informazione personale e gli eventuali dati identificabili non verranno memorizzati. Se si desidera, è possibile impedire il salvataggio di alcuni o tutti i cookie. Tuttavia, in questo caso l’utilizzo del sito e dei servizi offerti potrebbe risultarne compromesso. Per procedere senza modificare le opzioni relative ai cookies è sufficiente continuare con la navigazione.
            <br/>Di seguito le tipologie di cookies di cui il sito fa uso:</p>
        <h2 class="text-3">2 – Cookies tecnici</h2>
        <p>1. Ci sono numerose tecnologie usate per conservare informazioni nel computer dell’utente, che poi vengono raccolte dai siti. Tra queste la più conosciuta e utilizzata è quella dei cookies HTML. Essi servono per la navigazione e per facilitare l’accesso e la fruizione del sito da parte dell’utente. Sono necessari alla trasmissione di comunicazioni su rete elettronica ovvero al fornitore per erogare il servizio richiesto dal cliente.</p>
        <p>2. Le impostazioni per gestire o disattivare i cookies possono variare a seconda del browser internet utilizzato. Ad ogni modo, l’utente può gestire o richiedere la disattivazione generale o la cancellazione dei cookies, modificando le impostazioni del proprio browser internet. Tale disattivazione può rallentare o impedire l’accesso ad alcune parti del sito.</p>    
        <p>3. L’uso di cookies tecnici consente la fruizione sicura ed efficiente del sito.</p>
        <p>4. I cookies che vengono inseriti nel browser e ritrasmessi mediante Google Analytics o tramite il servizio statistiche di blogger o similari sono tecnici solo se utilizzati a fini di ottimizzazione del sito direttamente dal titolare del sito stesso, che potrà raccogliere informazioni in forma aggregata sul numero degli utenti e su come questi visitano il sito. A queste condizioni, per i cookies analytics valgono le stesse regole, in tema di informativa e consenso, previste per i cookies tecnici.</p>
        <p>5. Dal punto di vista della durata si possono distinguere cookies temporanei di sessione che si cancellano automaticamente al termine della sessione di navigazione e servono per identificare l’utente e quindi evitare il login ad ogni pagina visitata e quelli permanenti che restano attivi nel pc fino a scadenza o cancellazione da parte dell’utente.</p>
        <p>6. Potranno essere installati cookies di sessione al fine di consentire l’accesso e la permanenza nell’area riservata del portale come utente autenticato.</p>
        <p>7. Essi non vengono memorizzati in modo persistente ma esclusivamente per la durata della navigazione fino alla chiusura del browser e svaniscono con la chiusura dello stesso. Il loro uso è strettamente limitato alla trasmissione di identificativi di sessione costituiti da numeri casuali generati dal server necessari per consentire l’esplorazione sicura ed efficiente del sito.</p>
        <h2 class="text-3">3 – Cookies di terze parti</h2>
        <p>1. In relazione alla provenienza si distinguono i cookies inviati al browser direttamente dal sito che si sta visitando e quelli di terze parti inviati al computer da altri siti e non da quello che si sta visitando.</p>
        <p>2. I cookies permanenti sono spesso cookies di terze parti.</p>
        <p>3. La maggior parte dei cookies di terze parti è costituita da cookies di tracciamento usati per individuare il comportamento online, capire gli interessi e quindi personalizzare le proposte pubblicitarie per gli utenti.</p>
        <p>4. Potranno essere installati cookies di terze parti analitici. Essi sono inviati da domini di predette terze parti esterni al sito.</p>
        <p>5. I cookies analitici di terze parti sono impiegati per rilevare informazioni sul comportamento degli utenti su lavalleviaggi.it. La rilevazione avviene in forma anonima, al fine di monitorare le prestazioni e migliorare l’usabilità del sito. I cookies di profilazione di terze parti sono utilizzati per creare profili relativi agli utenti, al fine di proporre messaggi pubblicitari in linea con le scelte manifestate dagli utenti medesimi.</p>
        <p>6. L’utilizzo di questi cookies è disciplinato dalle regole predisposte dalle terze parti medesime, pertanto, si invitano gli utenti a prendere visione delle informative privacy e delle indicazioni per gestire o disabilitare i cookies pubblicate nelle relative pagine web.</p>    
        <h2 class="text-3">Google Analytics</h2>
        <p>Questo sito web utilizza Google Analytics, un servizio di analisi web fornito da Google , Inc. ( “Google”) . Google Analytics utilizza dei “cookies”, che sono file di testo depositati sul Vostro computer per consentire al sito web di analizzare come gli utenti utilizzano il sito . Le informazioni generate dal cookie sull’utilizzo del sito web (compreso il Vostro indirizzo IP anonimo) verranno trasmesse e depositate presso i server di Google negli Stati Uniti. Google utilizzerà queste informazioni allo scopo di esaminare il Vostro utilizzo del sito web, compilare report sulle attività del sito per gli operatori dello stesso e fornire altri servizi relativi alle attività del sito web e all’utilizzo di internet. Google può anche trasferire queste informazioni a terzi ove ciò sia imposto dalla legge o laddove tali terzi trattino le suddette informazioni per conto di Google . Google non assocerà il vostro indirizzo IP a nessun altro dato posseduto da Google. Potete rifiutarvi di usare i cookies selezionando l’impostazione appropriata sul vostro browser, ma si prega di notare che se si fa questo non si può essere in grado di utilizzare tutte le funzionalità di questo sito web . Utilizzando il presente sito web, voi acconsentite al trattamento dei Vostri dati da parte di Google per le modalità ed i fini sopraindicati.</p>
        <h2 class="text-3">4 – Cookies di profilazione</h2>
        <p>1. Sono cookie di profilazione quelli a creare profili relativi all’utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell’ambito della navigazione in rete.</p>
        <p>2. Quando si utilizzeranno questi tipi di cookies l’utente dovrà dare un consenso esplicito.</p>
        <p>3. Si applicherà l’articolo 22 del Regolamento (UE) 2016/679 e l’articolo 122 del Codice in materia di protezione dei dati.</p>
        <h2 class="text-3">Come posso disattivare i cookies?</h2>
        <p>Mozilla Firefox (about:preferences#privacy)<br/>
            Apri Firefox<br>           
                Premi il pulsante “Alt” sulla tastiera<br>
                Nella barra degli strumenti situata nella parte superiore del browser, seleziona “Strumenti “ e successivamente “Opzioni”<br>
                Seleziona quindi la scheda “Privacy” e vai su “Cookies and Site Data”</p>
        <p>Microsoft Edge (edge://settings/content/cookies)<br>
            Apri Microsoft Edge<br>
            Clicca sul pulsante “Strumenti” e quindi su “Cookies e autorizzazioni”<br>
            Seleziona la scheda “Cookies” dove è possibile deselezionare i cookies e salvare le preferenze</p>    
        <p>Google Chrome (chrome://settings/cookies):<br>
            Apri Google Chrome<br>
            Clicca sull'icona “Strumenti”<br>
            Seleziona “Impostazioni” e successivamente “Impostazioni avanzate”<br>
            Seleziona “Impostazioni dei contenuti” sotto la voce “Privacy”<br>
            Nella scheda “Cookies” è possibile deselezionare i cookies e salvare le preferenze</p>   
        <p>Safari:<br>
            Apri Safari<br>
            Scegli “Preferenze” nella barra degli strumenti, quindi seleziona il pannello “Sicurezza” nella finestra di dialogo che segue<br>
            Nella sezione “Accetta cookie” è possibile specificare se e quando Safari deve salvare i cookies dai siti web. Per ulteriori informazioni clicca sul pulsante di Aiuto (contrassegnato da un punto interrogativo)<br>
            Per maggiori informazioni sui cookies che vengono memorizzati sul vostro computer, clicca su “Mostra cookie”</p> 
        <h2 class="text-1">ULTIMO AGGIORNAMENTO: maggio 2021</h2>
       
      </div>
    </div>
    
  </div><!-- Content end -->

