import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[checkMin][formControlName],[checkMin][formControl],[checkMin][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: CheckMinDirective, multi: true}]
})
export class CheckMinDirective implements Validator {
  @Input()
  checkMin: number;

  validate(c: FormControl): {[key: string]: any} {
      let v = c.value;
      return ( v < this.checkMin)? {"checkMin": true} : null;
  }
}
