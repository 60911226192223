<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1>Privacy Policy</h1>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li><a href="index.html">Home</a></li>
            <li class="active">Privacy Policy</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Page Header end -->
  <!-- Content
  ============================================= -->
  <div id="content">
    <div class="container">
      <div class="bg-white shadow-md rounded p-4">
        <h2 class="text-6">I – INFORMAZIONI RELATIVE ALLA PRIVACY POLICY DEL SITO</h2>
        <p>1. In questa sezione sono contenute le informazioni relative alle modalità di gestione di lavalleviaggi.it in riferimento al trattamento dei dati degli utenti di lavalleviaggi.it.</p>
        <p>2. La presente informativa ha valore anche ai fini dell’articolo 13 del Regolamento (UE) n. 2016/679, relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali nonché alla libera circolazione di tali dati, per i soggetti che interagiscono con lavalleviaggi.it ed è raggiungibile all’indirizzo corrispondente alla pagina iniziale: www.lavalleviaggi.it</p>
        <p>3. L’informativa è resa solo per lavalleviaggi.it e non anche per altri siti web eventualmente consultati dall’utente tramite link in esso contenuti.</p>
        <p>4. Scopo del presente documento è fornire indicazioni circa le modalità, i tempi e la natura delle informazioni che i titolari del trattamento devono fornire agli utenti al momento della connessione alle pagine web di lavalleviaggi.it, indipendentemente dagli scopi del collegamento stesso, secondo la legislazione Italiana ed Europea.</p>
        <p>5. L’informativa può subire modifiche a causa dell’introduzione di nuove norme al riguardo, si invita pertanto l’utente a controllare periodicamente la presente pagina.</p>
        <p>6. Se l’utente ha meno di 14 anni, ai sensi dell’art.8, c.1 regolamento (UE) 2016/679, e dell’Art. 2 – Quinquies del D.Lgs 196/2003, così come modificato dal D.Lgs 181/18, dovrà legittimare il suo consenso attraverso l’autorizzazione dei genitori o di chi ne fa le veci.</p>
        <h2 class="text-6">II – TRATTAMENTO DEI DATI</h2>
        <h2 class="text-2">I – INFORMAZIONI RELATIVE ALLA PRIVACY POLICY DEL SITO</h2>
        <p>1. Il titolare del trattamento è la persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali. Si occupa anche dei profili sulla sicurezza.</p>
        <p>2. Relativamente al presente sito web il titolare del trattamento è: Manuel De Rosa, e per ogni chiarimento o esercizio dei diritti dell’utente potrà contattarlo al seguente indirizzo mail: direzione@lavalleviaggi.it.</p>
        <h2 class="text-2">2 – Responsabile del trattamento dati</h2>
        <p>1. Il responsabile del trattamento è la persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che tratta dati personali per conto del titolare del trattamento.</p>
        <p>2. Ai sensi dell’articolo 28 del regolamento (UE) n. 2016/679, su nomina del titolare del dati, il responsabile del trattamento dei dati del sito lavalleviaggi.it è: amministratore.</p>
        <h2 class="text-2">3 – Luogo trattamento dati</h2>
        <p>1. Il trattamento dei dati generato dall’utilizzo di lavalleviaggi.it avviene presso via Guicciardi, 9 42122 Reggio nell’Emilia.</p>
        <p>2. In caso di necessità, i dati connessi al servizio newsletter possono essere trattati dal responsabile del trattamento o soggetti da esso incaricati a tal fine presso la relativa sede.</p>
        <h2 class="text-6">III – DATI TRATTATI</h2>
        <h2 class="text-2">1 – Modalità trattamento dati</h2>
        <p>1. Come tutti i siti web anche il presente sito fa uso di log files nei quali vengono conservate informazioni raccolte in maniera automatizzata durante le visite degli utenti. Le informazioni raccolte potrebbero essere le seguenti:
            <br/>– indirizzo internet protocol (IP);
            <br/>– tipo di browser e parametri del dispositivo usato per connettersi al sito;
            <br/>– nome dell’internet service provider (ISP);
            <br/>– data e orario di visita;
            <br/>– pagina web di provenienza del visitatore (referral) e di uscita;
            <br/>– eventualmente il numero di click.
       </p>
       <p>2. Le suddette informazioni sono trattate in forma automatizzata e raccolte in forma esclusivamente aggregata al fine di verificare il corretto funzionamento del sito, e per motivi di sicurezza. Tali informazioni saranno trattate in base ai legittimi interessi del titolare.</p>
       <p>3. A fini di sicurezza (filtri antispam, firewall, rilevazione virus), i dati registrati automaticamente possono eventualmente comprendere anche dati personali come l’indirizzo Ip, che potrebbe essere utilizzato, conformemente alle leggi vigenti in materia, al fine di bloccare tentativi di danneggiamento al sito medesimo o di recare danno ad altri utenti, o comunque attività dannose o costituenti reato. Tali dati non sono mai utilizzati per l’identificazione o la profilazione dell’utente, ma solo a fini di tutela del sito e dei suoi utenti, tali informazioni saranno trattate in base ai legittimi interessi del titolare.</p>
       <p>4. Qualora il sito consenta l’inserimento di commenti, oppure in caso di specifici servizi richiesti dall’utente, ivi compresi la possibilità di inviare il Curriculum Vitae per un eventuale rapporto lavorativo, il sito rileva automaticamente e registra alcuni dati identificativi dell’utente, compreso l’indirizzo mail. Tali dati si intendono volontariamente forniti dall’utente al momento della richiesta di erogazione del servizio. Inserendo un commento o altra informazione l’utente accetta espressamente l’informativa privacy, e in particolare acconsente che i contenuti inseriti siano liberamente diffusi anche a terzi. I dati ricevuti verranno utilizzati esclusivamente per l’erogazione del servizio richiesto e per il solo tempo necessario per la fornitura del servizio.</p>
       <p>5. Le informazioni che gli utenti del sito riterranno di rendere pubbliche tramite i servizi e gli strumenti messi a disposizione degli stessi, sono fornite dall’utente consapevolmente e volontariamente, esentando il presente sito da qualsiasi responsabilità in merito ad eventuali violazioni delle leggi. Spetta all’utente verificare di avere i permessi per l’immissione di dati personali di terzi o di contenuti tutelati dalle norme nazionali ed internazionali.</p>
       <h2 class="text-2">2 – Finalità del trattamento dati</h2>
       <p>1. I dati raccolti dal sito durante il suo funzionamento sono utilizzati esclusivamente per le finalità sopra indicate e conservati per il tempo strettamente necessario a svolgere le attività precisate, e comunque non oltre 1 mese.</p>
       <p>2. I dati utilizzati a fini di sicurezza (blocco tentativi di danneggiamento del sito) sono conservati per il tempo strettamente necessario al raggiungimento del fine anteriormente indicato.</p>
       <h2 class="text-2">3 – Dati forniti dall’utente</h2>
       <p>1. Come sopra indicato, l’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva.</p>
       <p>2. Specifiche informative di sintesi verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.</p>
       <h2 class="text-6">IV. DIRITTI DELL’UTENTE</h2>
       <p>1. L’art. 13, c. 2 del Regolamento (UE) 2016/679 elenca i diritti dell’utente.</p>>
       <p>2. Il sito lavalleviaggi.it intende pertanto informare l’utente sull’esistenza dei diritti dell’utente, in base ai seguenti articoli del Regolamento (UE) 2016/679:</p>
       <p>a) In base all’art. 15, del diritto dell’interessato di chiedere al titolare l’accesso ai dati personali, in base all’art. 16 la possibilità di rettificare i dati forniti, in base all’art.18 la possibilità di integrare o limitare il trattamento che lo riguardino, o di opporsi, per motivi legittimi, al loro trattamento in base all’art. 21, oltre al diritto alla portabilità dei dati in base all’art. 20 Regolamento (UE);</p>
       <p>b) del diritto di chiedere la cancellazione in base all’art.17, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati.</p>
       <p>c) del diritto di ottenere l’attestazione che le operazioni di aggiornamento, rettificazione, integrazione dei dati, cancellazione, blocco dei dati, trasformazione sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.</p>
       <p>3. Le richieste possono essere indirizzate al titolare del trattamento, senza formalità o, in alternativa, utilizzando il modello previsto dal Garante per la Protezione dei Dati Personali, o inviando una mail all’indirizzo: direzione@lavalleviaggi.it</p>
       <p>4. Qualora il trattamento sia basato sull’art. 6, paragrafo 1, lett. a) – consenso espresso all’utilizzo – oppure sull’art. 9, paragrafo 2 lett. a) – consenso espresso all’utilizzo di dati genetici, biometrici, relativi alla salute, che rivelino convinzioni religiose, o filosofiche o appartenenza sindacale, che rivelino l’origine razziale o etnica, le opinioni politiche – l’utente ha il diritto di revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca.</p>
       <p>5. Parimenti, in caso di violazione della normativa, l’utente ha il diritto di proporre reclamo presso il Garante per la Protezione dei Dati Personali, quale autorità preposta al controllo sul trattamento nello Stato Italiano.</p>
       <p>6. Per una disamina più approfondita dei diritti che Le competono, si vedano gli articoli 15-22 del Regolamento (UE) 2016/679.</p>
       <h2 class="text-6">V. SICUREZZA DATI FORNITI</h2>
       <p>1. Il presente sito tratta i dati degli utenti in maniera lecita e corretta, adottando le opportune misure di sicurezza volte ad impedire accessi non autorizzati, divulgazione, modifica o distruzione non autorizzata dei dati. Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate.</p>
       <p>2. Oltre al titolare, in alcuni casi, potrebbero avere accesso ai dati categorie di incaricati coinvolti nell’organizzazione del sito (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione).</p>
       <h2 class="text-1">ULTIMO AGGIORNAMENTO: maggio 2021</h2>
       
      </div>
    </div>
    
  </div><!-- Content end -->
  
