<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1>{{'novita_headertitle' | translate}}</h1>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li><a href="index.html">Home</a></li>
            <li class="active">{{'novita_headertitle' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Page Header end -->
  <!-- Content
  ============================================= -->
  <div id="content">
    <div class="container">
      <div class="row">

        <!-- Middle Panel
        ============================================= -->
        <div class="col-lg-8 col-xl-9">
          <div class="blog-post card shadow-sm border-0 mb-4" *ngFor="let news of listaNews; let i = index">
            <div class="row no-gutters p-3">
              <div class="col-md-5"> <a [routerLink]="" (click)="onSelect(news)" ><img class="card-img d-block" [src]="listImage540x390[i]" alt=""> </a> </div>
              <div class="col-md-7">
                <div class="card-body pb-0 pt-3 pt-md-0 pl-0 pl-md-4 pr-0">
                  <h4 class="title-blog text-5"><a [routerLink]="" (click)="onSelect(news)" >{{news.titolo}}</a></h4>
                  <ul class="meta-blog">
                    <li><i class="fa fa-calendar"></i> {{news.data}}</li>
                    <li><a a [routerLink]="" (click)="onRefreshCategoria(news.codicecategoria)"><i class="fa fa-file-archive-o"></i> {{news.categoria}}</a></li>
                  </ul>
                  <p [innerHTML]="news.shortdescrizione"></p>
                  <button class="btn btn-primary btn-sm" type="submit" (click)="onSelect(news)">Leggi Tutto</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Pagination
          ============================================= -->
          <!--
          <ul class="pagination justify-content-center mt-5 mb-5">
            <li class="page-item disabled"> <a class="page-link" href="#" tabindex="-1"><i class="fa fa-angle-left"></i></a> </li>
            <li class="page-item active"> <a class="page-link" href="#">1</a> </li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item d-flex align-content-center flex-wrap text-muted text-5 mx-1">......</li>
            <li class="page-item"><a class="page-link" href="#">15</a></li>
            <li class="page-item"> <a class="page-link" href="#"><i class="fa fa-angle-right"></i></a> </li>
          </ul>
          -->          
          <!-- Paginations end -->

        </div>
        <!-- Middle Panel End -->

        <!-- Right Sidebar
        ============================================= -->
        <aside class="col-lg-4 col-xl-3">
          <!-- Search
		  =============================== -->
          <div class="input-group shadow-sm mb-4">
            <input class="form-control shadow-none border-0 pr-0" type="search" id="search-input" placeholder="{{'generic_cerca' | translate}}" [(ngModel)]="filtroDescrizione" name="filtroDescrizione">
            <div class="input-group-append"> <span class="input-group-text bg-white border-0 p-0">
              <button class="btn text-muted shadow-none px-3 border-0" type="button" (click)="onRefresh()"><i class="fa fa-search"></i></button>
              </span> </div>
          </div>

          <!-- Categories
		  =============================== -->
          <div class="bg-white shadow-sm rounded p-3 mb-4">
            <h4 class="text-5">{{'novita_categorie' | translate}}</h4>
            <hr class="mx-n3">
            <ul class="list-item" *ngFor="let categoria of listaNewsCategorie; let i = index">
              <li><a [routerLink]="" (click)="onRefreshCategoria(categoria.codice)">{{categoria.descrizione}}<span>({{categoria.numeronews}})</span></a></li>
            </ul>
          </div>

          <!-- Recent Posts
          =============================== -->
          <div class="bg-white shadow-sm rounded p-3 mb-4">
            <h4 class="text-5">{{'novita_ultimenovita' | translate}}</h4>
            <hr class="mx-n3">
            <div class="side-post">
              <div class="item-post" *ngFor="let newsrecent of listaNewsRecenti | slice:0:3; let i = index">
                <div class="img-thumb"><a [routerLink]="" (click)="onSelect(newsrecent)"><img class="rounded" [src]="listImage65x65[i]" title="" alt=""></a></div>
                <div class="caption"> <a [routerLink]="" (click)="onSelect(newsrecent)">{{newsrecent.titolo}}</a>
                  <p class="date-post">{{newsrecent.data}}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Popular Tags
          =============================== -->
          <!--
          <div class="bg-white shadow-sm rounded p-3 mb-4">
            <h4 class="text-5">Popular Tags</h4>
            <hr class="mx-n3">
            <div class="tags"> <a href="#">Recharge</a> <a href="#">Tips</a> <a href="#">2021</a> <a href="#">Booking</a> <a href="#">Marketing</a> <a href="#">Bill</a> <a href="#">How To</a> <a href="#">Payment</a> <a href="#">Offers</a>  </div>
          </div>
          -->
        </aside>
        <!-- Right Sidebar End -->

      </div>
    </div>
  </div>
  <!-- Content end -->
