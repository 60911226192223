<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1>{{'checkout_headertitle' | translate}}</h1>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li><a href="index.html">Home</a></li>
          <li><a class="cursor-pointer" onclick="history.back();">{{'risultati_headertitle' | translate}}</a></li>
          <li class="active">{{'checkout_headertitle' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Page Header end -->
<div id="content">
  <section class="container">
    <form (ngSubmit)="formCheckout.valid && checkout()" #formCheckout=ngForm>
    <div class="row">
      <div class="col-lg-8">
        <div class="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
          <h2 class="text-6 mb-3 mb-sm-4">{{'checkout_confermadettagliviaggio' | translate}}</h2>
    <hr class="mx-n3 mx-sm-n4 mb-4">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center trip-title">
                <div class="col-5 col-sm-auto text-center text-sm-left">
                  <h5 class="m-0 trip-place">{{biglietto.viaggioAndata.cittaPartenza}}</h5>
                </div>
                <div class="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                <div class="col-5 col-sm-auto text-center text-sm-left">
                  <h5 class="m-0 trip-place">{{biglietto.viaggioAndata.cittaArrivo}}</h5>
                </div>
                <div class="col-12 mt-1 d-block d-md-none"></div>
                <div class="col-6 col-sm col-md-auto text-3 date">{{biglietto.viaggioAndata.dataPartenza}}</div>
                <!--<div class="col-6 col-sm col-md-auto text-right order-sm-1"><a *ngIf="!biglietto.viaggioAndata.sceltaPostiBloccata"  class="link" (click)="openSelezionePosti(templateSelPosti,biglietto.viaggioAndata)">{{'checkout_selezionaposti' | translate}}</a></div>-->
                <!--<div class="col col-md-auto text-center ml-auto order-sm-0"><span class="badge badge-success py-1 px-2 font-weight-normal text-1">Refundable</span></div>-->
              </div>
            </div>
            <div class="card-body">
              <div class="row align-items-sm-center flex-row">
                <div class="col-12 col-sm-3 mb-3 mb-sm-0"> <span class="text-3 text-dark operator-name">{{biglietto.passeggeriAdulti.length}} {{biglietto.passeggeriAdulti.length>1?('checkout_persone' | translate):('checkout_persona' | translate)}}</span> <!--<span class="text-muted d-block">La Valle</span>--> </div>
                <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{biglietto.viaggioAndata.orarioPartenza}}</span> <small class="text-muted d-block">{{biglietto.viaggioAndata.localitaPartenza}}</small> </div>
                <div class="col col-sm-3 text-center d-none d-sm-block time-info"> <span class="text-3 duration">{{biglietto.viaggioAndata.tratte.length-1}}  {{'risultati_cambi' | translate}}</span> <small class="text-muted d-block">{{biglietto.viaggioAndata.durataText}}</small> </div>
                <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{biglietto.viaggioAndata.orarioArrivo}}</span> <small class="text-muted d-block">{{biglietto.viaggioAndata.localitaArrivo}}</small> </div>
                <div class="col-12 mt-3 text-dark" *ngFor="let tratta of biglietto.viaggioAndata.tratte" >{{tratta.cittaPartenza}} - {{tratta.cittaArrivo}} <br/><a *ngIf="!tratta.sceltaPostiBloccata"  class="cursor-pointer secondary-color" (click)="openSelezionePosti(templateSelPosti,tratta)">{{'checkout_postiselezionati' | translate}}</a>: <span class="bg-success text-light rounded px-2" *ngFor="let posto of tratta.postiSelezionati" >{{posto}}</span> </div>
              </div>
            </div>
          </div>
          <div class="card mt-2" *ngIf="biglietto.viaggioRitorno" >
            <div class="card-header">
              <div class="row align-items-center trip-title">
                <div class="col-5 col-sm-auto text-center text-sm-left">
                  <h5 class="m-0 trip-place">{{biglietto.viaggioRitorno.cittaPartenza}}</h5>
                </div>
                <div class="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                <div class="col-5 col-sm-auto text-center text-sm-left">
                  <h5 class="m-0 trip-place">{{biglietto.viaggioRitorno.cittaArrivo}}</h5>
                </div>
                <div class="col-12 mt-1 d-block d-md-none"></div>
                <div class="col-6 col-sm col-md-auto text-3 date">{{biglietto.viaggioRitorno.dataPartenza}}</div>
                <div class="col-6 col-sm col-md-auto text-right order-sm-1"><a *ngIf="!biglietto.viaggioRitorno.sceltaPostiBloccata" class="link" (click)="openSelezionePosti(templateSelPosti,biglietto.viaggioRitorno)" >{{'checkout_selezionaposti' | translate}}</a></div>
                <!--<div class="col col-md-auto text-center ml-auto order-sm-0"><span class="badge badge-success py-1 px-2 font-weight-normal text-1">Refundable</span></div>-->
              </div>
            </div>
            <div class="card-body">
              <div class="row align-items-sm-center flex-row">
                <div class="col-12 col-sm-3 mb-3 mb-sm-0"> <span class="text-3 text-dark operator-name">La Valle Interlines</span> <!--<span class="text-muted d-block">La Valle</span>--> </div>
                <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{biglietto.viaggioRitorno.orarioPartenza}}</span> <small class="text-muted d-block">{{biglietto.viaggioRitorno.localitaPartenza}}</small> </div>
                <div class="col col-sm-3 text-center d-none d-sm-block time-info"> <span class="text-3 duration">{{biglietto.viaggioRitorno.tratte.length-1}}  {{'risultati_cambi' | translate}}</span> <small class="text-muted d-block">{{biglietto.viaggioRitorno.durataText}}</small> </div>
                <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{biglietto.viaggioRitorno.orarioArrivo}}</span> <small class="text-muted d-block">{{biglietto.viaggioRitorno.localitaArrivo}}</small> </div>
                <div class="col-12 mt-3 text-dark">Seat No(s): <span class="bg-success text-light rounded px-2">12</span> <span class="bg-success text-light rounded px-2">13</span></div>
              </div>

            </div>
          </div>
          <div class="alert alert-info mt-4"> <span class="badge badge-info">NOTE:</span> This is a special fare given by the Operator. Operator cancellation charges do apply. </div>
          <h2 class="text-6 mb-3 mt-5">{{'checkout_dettagliopasseggeri' | translate}} - <span class="text-3" *ngIf="!userInfo.autenticato" ><a class="link" title="Login" (click)="openLoginComponent()">Login</a> {{'checkout_loginprenotazioneveloce' | translate}}</span></h2>
          <p class="font-weight-600">{{'checkout_dettaglidicontatto' | translate}}</p>
          <div class="form-row">
            <div class="col-sm-6 form-group">
              <input class="form-control" name="txtContattoEmail" [(ngModel)]="biglietto.emailContatto" required placeholder="{{'checkout_inserisciemail' | translate}}" type="text">
            </div>
            <div class="col-sm-6 form-group">
              <input class="form-control" data-bv-field="number" name="txtContattoTelefono" [(ngModel)]="biglietto.telefonoContatto" placeholder="{{'checkout_inseriscicellulare' | translate}}" type="number">
            </div>
          </div>
          <p class="text-info">{{'checkout_descrizioneinfocontatto' | translate}}</p>
          <div *ngFor="let passeggero of biglietto.passeggeriAdulti; let i = index;" >
            <p class="font-weight-600">{{'checkout_adulto' | translate}} {{i+1}}</p>
            <div class="form-row">
              <div class="col-sm-4 form-group">
                <input class="form-control" name="txtNomeAdulto{{i+1}}" required [(ngModel)]='passeggero.nome' placeholder="{{'checkout_nome' | translate}}" type="text">
              </div>
              <div class="col-sm-4 form-group">
                <input class="form-control" name="txtCognomeAdulto{{i+1}}" required [(ngModel)]='passeggero.cognome' placeholder="{{'checkout_cognome' | translate}}" type="text">
              </div>
              <div class="col-sm-2 form-group">
                <input class="form-control" name="txtEtaAdulto{{i+1}}" required checkMin="12" [(ngModel)]='passeggero.eta' placeholder="{{'checkout_eta' | translate}}" type="number">
              </div>
            </div>
          </div>

          <div *ngFor="let item of biglietto.passeggeriBambini; let i = index;" >
            <p class="font-weight-600">{{'checkout_bambino' | translate}} {{i+1}}</p>
            <div class="form-row">
              <div class="col-sm-4 form-group">
                <input class="form-control" name="txtNomeBambino{{i+1}}" required placeholder="{{'checkout_nome' | translate}}" type="text">
              </div>
              <div class="col-sm-4 form-group">
                <input class="form-control" name="txtCognomeBambino{{i+1}}" required placeholder="{{'checkout_cognome' | translate}}" type="text">
              </div>
              <div class="col-sm-2 form-group">
                <input class="form-control" name="txtEtaBambino{{i+1}}" required checkMin="0" checkMax="11" placeholder="{{'checkout_eta' | translate}}" type="number">
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Side Panel (Fare Details)
      ============================================= -->
      <aside class="col-lg-4 mt-4 mt-lg-0">
        <div class="bg-white shadow-md rounded p-3">
          <h3 class="text-5 mb-3">{{'checkout_dettagliotariffa' | translate}}</h3>
          <hr class="mx-n3">
          <h3 class="text-4 mb-3 mt-4">{{'checkout_codicepromozionale' | translate}}</h3>
          <div class="input-group form-group">
            <input class="form-control" placeholder="Promo Code" name="txtPromoCode" [(ngModel)]="biglietto.codicePromozionale" aria-label="Promo Code" type="text">
            <span class="input-group-append">
            <button class="btn btn-secondary" type="submit">{{'checkout_applica' | translate}}</button>
            </span>
          </div>
          <ul class="list-unstyled">
            <li class="mb-2">{{'checkout_tariffabase' | translate}} <span class="float-right text-4 font-weight-500 text-dark">€ {{biglietto.viaggioAndata.prezzoTotale}}</span></li>
            <li class="mb-2">{{biglietto.viaggioAndata.cittaPartenza}} - {{biglietto.viaggioAndata.cittaArrivo}}<span class="float-right text-2"><i class="mr-1 fa fa-male"></i>{{biglietto.passeggeriAdulti.length}} <i class="ml-1 mr-1 fa fa-child"></i>{{biglietto.passeggeriBambini.length}}</span></li>
            <!--<li class="mb-2">Taxes & Fees <span class="float-right text-4 font-weight-500 text-dark">$30</span></li>
            <li class="mb-2">Insurance <span class="float-right text-4 font-weight-500 text-dark">$70</span></li>-->
          </ul>
          <ul class="list-unstyled" *ngIf="biglietto.viaggioRitorno" >
            <li class="mb-2">{{'checkout_tariffabase' | translate}} <span class="float-right text-4 font-weight-500 text-dark">€ {{biglietto.viaggioRitorno.prezzoTotale}}</span></li>
            <li class="mb-2">{{biglietto.viaggioRitorno.cittaPartenza}} - {{biglietto.viaggioRitorno.cittaArrivo}}</li>
          </ul>

          <div class="text-dark bg-light-4 text-4 font-weight-600 p-3 mb-3"> {{'checkout_importototale' | translate}} <span class="float-right text-6">€ {{biglietto.viaggioRitorno?biglietto.viaggioAndata.prezzoTotale + biglietto.viaggioRitorno.prezzoTotale:biglietto.viaggioAndata.prezzoTotale}}</span> </div>
          <button class="btn btn-primary btn-block" [disabled]="formCheckout.invalid">{{'checkout_procedipagamento' | translate}}</button>
        </div>
      </aside><!-- Side Panel -->
    </div>
  </form>
  </section>

  <!-- Fare Rules (Modal Dialog) ============================================= -->
  <ng-template #templateSelPosti>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{'checkout_selezionaposti' | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
      <div class="bus-details">
        <div class="row align-items-sm-center flex-row mb-4">
          <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{trattaSelezionata.cittaPartenza}}</span> <small class="text-muted d-block">{{trattaSelezionata.localitaPartenza}}</small> </div>
          <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{trattaSelezionata.orarioPartenza}}</span> </div>
          <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{trattaSelezionata.cittaArrivo}}</span> <small class="text-muted d-block">{{trattaSelezionata.localitaArrivo}}</small> </div>
          <div class="col col-sm-3 text-center time-info"> <span class="text-5 text-dark">{{trattaSelezionata.orarioArrivo}}</span></div>
        </div>

        <div class="my-3" >
          <div class="row">
            <div class="col-12 col-lg-6 text-center">
              <p class="text-muted text-1">Click on Seat to select/ deselect</p>
              <div>
                <tabset [justified]="true">
                  <tab heading="{{'checkout_pianoinferiore' | translate}}" class="mt-3" *ngIf="matriceposti_piano0">
                    <div id="seat-map" class="seatCharts-container">
                      <div class="seatCharts-row" *ngFor="let fila of matriceposti_piano0; let i=index;">
                          <div class="seatCharts-cell seatCharts-space">{{matriceposti_piano0.length-i}}</div>
                          <div *ngFor="let posto of fila" [ngClass]="getStyleClassSeat(posto,false)" (click)="selezionaPosto(posto)" >{{posto.seat=='C' || posto.seat=='NA'  || posto.seat=='' ? '':posto.seat}}</div>
                      </div>
                      <div class="front-indicator">{{'checkout_fronte' | translate}}</div>
                    </div>
                    <div id="legend"></div>
                  </tab>
                  <tab heading="{{'checkout_pianosuperiore' | translate}}" class="mt-3" *ngIf="matriceposti_piano1">
                    <div id="seat-map" class="seatCharts-container">
                      <div class="seatCharts-row" *ngFor="let fila of matriceposti_piano1; let i=index;">
                          <div class="seatCharts-cell seatCharts-space">{{matriceposti_piano1.length-i}}</div>
                          <div *ngFor="let posto of fila" [ngClass]="getStyleClassSeat(posto,false)" (click)="selezionaPosto(posto)" >{{posto.seat=='C' || posto.seat=='NA'  || posto.seat=='' ? '':posto.seat}}</div>
                      </div>
                      <div class="front-indicator">{{'checkout_fronte' | translate}}</div>
                    </div>
                    <div id="legend"></div>
<!--
                    <div id="1_1" role="checkbox" aria-checked="false" focusable="true" tabindex="-1" class="seatCharts-seat seatCharts-cell available first-class">1</div>
                    <div id="1_2" role="checkbox" aria-checked="false" focusable="true" tabindex="-1" class="seatCharts-seat seatCharts-cell first-class unavailable">2</div>
                    <div class="seatCharts-cell seatCharts-space"></div>
                    <div id="1_4" role="checkbox" aria-checked="false" focusable="true" tabindex="-1" class="seatCharts-seat seatCharts-cell first-class available">3</div>
                    <div id="1_5" role="checkbox" aria-checked="false" focusable="true" tabindex="-1" class="seatCharts-seat seatCharts-cell available first-class">4</div>
-->

                  </tab>
                </tabset>
              </div>
            </div>
            <div class="col-12 col-lg-6 mt-3 mt-lg-0">
              <div class="booking-details">
                <h2 class="text-5">Booking Details</h2>
                <p>{{'checkout_postiselezionati' | translate}} (<span id="counter">{{this.trattaSelezionata.postiSelezionati.length}}/{{biglietto.passeggeriAdulti.length+biglietto.passeggeriBambini.length}}</span>):</p>
                <ul id="selected-seats">
                  <li *ngFor="let postoSelezionato of this.trattaSelezionata.postiSelezionati">{{'checkout_posto' | translate}} N. <b>{{postoSelezionato}}</b>: <a class="cancel-cart-item text-danger text-4" (click)="rimuoviPosto(postoSelezionato)" ><i class="fa fa-times-circle"></i></a></li>
                </ul>
                <button class="btn btn-primary btn-block" (click)="confermaSelezionePosti()" >{{'checkout_continua' | translate}}</button>
                <div class="mt-3 bg-primary-color-disabled p-2 rounded" *ngIf="errorImpegnoPosti" ><span class="text-light "><strong>{{'checkout_spiacenti' | translate}}, </strong>{{'checkout_postisceltigiaoccupati' | translate}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- Fare Rules end -->

</div><!-- Content end -->
