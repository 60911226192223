<!-- Page Header============================================= -->
<!--
<section class="page-header page-header-text-light bg-primary">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1>{{'azienda_headertitle' | translate}}</h1>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li><a href="index.html">Home</a></li>
          <li class="active">{{'azienda_headertitle' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</section>-->
<!-- Page Header end -->

<div class="hero-wrap py-4 py-md-5">
  <div class="hero-mask"></div>
  <div class="hero-bg" style="background-image:url('./assets/images/bg/milano.jpg'); background-position: left bottom;"></div>
  <div class="hero-content py-0 py-lg-3" style="height:300px;">
    <div class="container">
      <div class="row">

      </div>
    </div>
  </div>
</div>

<!-- Content
  ============================================= -->
  <div id="content" class="mt-4">
    <div class="container">
      <div class="row">
        <div class="bg-white shadow-md rounded p-4">
        <h2 class="text-6">{{this.infoazienda.titolo}}</h2>
        <p [innerHTML]="infoazienda.descrizione" ></p>
          <div class="row mt-4 mb-2">
          <div class="col-md-4">
            <div class="featured-box style-1">
              <div class="featured-box-icon text-primary"> <i class="fa fa-thumbs-up"></i></div>
              <h3>{{this.infoazienda.listbox[0].titolo}}</h3>
              <p [innerHTML]="infoazienda.listbox[0].descrizione"></p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featured-box style-1">
              <div class="featured-box-icon text-primary"> <i class="fa fa-paper-plane"></i> </div>
              <h3>{{this.infoazienda.listbox[1].titolo}}</h3>
              <p [innerHTML]="infoazienda.listbox[1].descrizione"></p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="featured-box style-1">
              <div class="featured-box-icon text-primary"> <i class="fa fa-eye"></i> </div>
              <h3>{{this.infoazienda.listbox[2].titolo}}</h3>
              <p [innerHTML]="infoazienda.listbox[2].descrizione"></p>
            </div>
          </div>
        </div>
        <!--
        <h2 class="text-6 mb-3">Il Nostro Staff</h2>
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="team"> <img class="img-fluid rounded" alt="" src="assets/images/team/leader-1.jpg">
              <h3>Mariella La Valle</h3>
              <p class="text-muted">Amministratore Unico</p>
              <ul class="social-icons social-icons-sm d-inline-flex">
                <li class="social-icons-facebook"><a data-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fa fa-facebook"></i></a></li>
                <li class="social-icons-twitter"><a data-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="team"> <img class="img-fluid rounded" alt="" src="assets/images/team/leader-2-2.jpg">
              <h3>Manuel De Rose</h3>
              <p class="text-muted">Direttore Noleggi</p>
              <ul class="social-icons social-icons-sm d-inline-flex">
                <li class="social-icons-facebook"><a data-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fa fa-facebook"></i></a></li>
                <li class="social-icons-twitter"><a data-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="team"> <img class="img-fluid rounded" alt="" src="assets/images/team/leader-3.jpg">
              <h3>Mario Parisella</h3>
              <p class="text-muted">Movimento e Logistica</p>
              <ul class="social-icons social-icons-sm d-inline-flex">
                <li class="social-icons-facebook"><a data-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fa fa-facebook"></i></a></li>
                <li class="social-icons-twitter"><a data-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li>
                <li class="social-icons-google"><a data-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i class="fa fa-google"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="team"> <img class="img-fluid rounded" alt="" src="assets/images/team/leader-4-1.jpg">
              <h3>Daniel Forte</h3>
              <p class="text-muted">Amministrazione</p>
              <ul class="social-icons social-icons-sm d-inline-flex">
                <li class="social-icons-facebook"><a data-toggle="tooltip" href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fa fa-facebook"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      -->
      </div>
    </div>
    </div>
  </div><!-- Content end -->
