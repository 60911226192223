import { NovitaSingleComponent } from './novita-single/novita-single.component';
import { PasswordDimenticataComponent } from './password-dimenticata/password-dimenticata.component';
import { PrenotazioneExpiredComponent } from './prenotazione-expired/prenotazione-expired.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SearchresultsComponent } from './searchresults/searchresults.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from './error404/error404.component';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LogoutComponent } from './logout/logout.component';
import { ContactComponent } from './contact/contact.component';
import { AziendaComponent } from './azienda/azienda.component';
import { SupportComponent } from './support/support.component';
import { NovitaComponent } from './novita/novita.component';
import { ServizioNonDisponibileComponent } from './servizio-non-disponibile/servizio-non-disponibile.component';
import { PagamentoComponent } from './pagamento/pagamento.component';
import { DetailNoleggioComponent } from './detail-noleggio/detail-noleggio.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiepageComponent } from './cookiepage/cookiepage.component';

const routes: Routes = [
  {path: '',  redirectTo: '/home', pathMatch: 'full' },
  {path:'home', component : HomeComponent},
  {path:'searchresults', component : SearchresultsComponent},
  {path:'detail-noleggio', component : DetailNoleggioComponent},
  {path:'checkout', component : CheckoutComponent},
  {path:'pagamento', component : PagamentoComponent},
  {path:'booking-expired', component : PrenotazioneExpiredComponent},
  {path:'confirm-registration', component : ConfirmRegistrationComponent},
  {path:'password-dimenticata', component : PasswordDimenticataComponent},
  {path:'service-unavailable', component : ServizioNonDisponibileComponent},
  {path:'profile', component : CheckoutComponent,canActivate: [AuthGuardService]},
  {path:'logout', component : LogoutComponent},
  {path:'contact', component : ContactComponent},
  {path:'azienda', component : AziendaComponent},
  {path:'support', component : SupportComponent},
  {path:'novita', component : NovitaComponent},
  {path:'novita-single', component : NovitaSingleComponent},
  {path:'privacy', component : PrivacyComponent},
  {path:'cookiepage', component : CookiepageComponent},
  {path:'**', component : HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
