import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CorseService {
  headers = new HttpHeaders(
    {'Authorization': 'Basic  '+btoa(environment.apiUrlUsername+":"+environment.apiUrlPwd)}
  )

  constructor(private router:Router ,private httpClient:HttpClient) {

  }

  getLocalCart(fieldid:string){
    return sessionStorage.getItem(fieldid);
  }
  addLocalCart(fieldid:string,value:string){
    sessionStorage.setItem(fieldid,value);
  }
  svuotaLocalCart(){
    sessionStorage.removeItem("cart");
    sessionStorage.removeItem("cart_id");
    sessionStorage.removeItem("cart_expire");
  }
  getPartenze(){
    let body = 'keyPortale='+environment.keyPortal;
    return this.httpClient.post(environment.apiUrl+'listPartenze',body, {headers:this.headers});
  }
  getDestinazioni(codiceCitta:number){
    let body = 'keyPortale='+environment.keyPortal+'&codiceCittaPartenza='+codiceCitta;
    return this.httpClient.post(environment.apiUrl+'listDestinazioniExtended',body, {headers:this.headers});
  }
  elencoCorse(codiceCittaPartenza:number,codiceCittaArrivo:number,dataPartenza:string,numAdulti:number,numBambini:number,numMaxCambi:number,filtroOraMin:string,filtroOraMax:string,filtroPrezzoMin:number,filtroPrezzoMax:number){
    let body = 'keyPortale='+environment.keyPortal+'&codiceCittaPartenza='+codiceCittaPartenza+'&codiceCittaArrivo='+codiceCittaArrivo+'&dataPartenza='+dataPartenza+'&numAdulti='+numAdulti+'&numBambini='+numBambini+'&numMaxCambi='+numMaxCambi+'&filtroOraMin='+filtroOraMin+'&filtroOraMax='+filtroOraMax+'&filtroPrezzoMin='+filtroPrezzoMin+'&filtroPrezzoMax='+filtroPrezzoMax;
    return this.httpClient.post<any>(environment.apiUrl+'elencoCorse',body, {headers:this.headers});
  }
  elencoPostiPrenotati(cartId:string,codiceCorsaWeb:number,codicePercorsoStart:number,codicePercorsoStop:number){
    let body = 'cartId='+cartId+'&codiceCorsaWeb='+codiceCorsaWeb+'&codicePercorsoStart='+codicePercorsoStart+'&codicePercorsoStop='+codicePercorsoStop;
    return this.httpClient.post<any>(environment.apiUrl+'getPostiBloccati',body, {headers:this.headers});
  }
  getMappaBus(codiceCorsaWeb:number){
    let body = 'codiceCorsaWeb='+codiceCorsaWeb;
    return this.httpClient.post<any>(environment.apiUrl+'getMappaBus',body, {headers:this.headers});
  }
  impegnoBiglietto(biglietto:string){
    let body = 'bigliettoJson='+biglietto;
    return this.httpClient.post<any>(environment.apiUrl+'impegnoBiglietto',body, {headers:this.headers});
  }
  disimpegnoBiglietto(cartId:string){
    let body = 'cartId='+cartId;
    return this.httpClient.post<any>(environment.apiUrl+'disimpegnoBiglietto',body, {headers:this.headers});
  }
  impegnoPosti(postiSelezionati:string,cartId:string,codiceCorsaWeb:number,codicePercorsoStart:number,codicePercorsoStop:number){
    let body = 'postiSelezionati='+postiSelezionati+'&cartId='+cartId+'&codiceCorsaWeb='+codiceCorsaWeb+'&codicePercorsoStart='+codicePercorsoStart+'&codicePercorsoStop='+codicePercorsoStop;
    return this.httpClient.post<any>(environment.apiUrl+'impegnoPosti',body, {headers:this.headers});
  }
  controlloValiditaCarrello(bigliettoJson:string,cartId:string,applicaDelayCarrello:boolean){
    let body = 'bigliettoJson='+bigliettoJson+'&cartId='+cartId+'&applicaDelay='+applicaDelayCarrello;
    return this.httpClient.post<any>(environment.apiUrl+'controlloValiditaCarrello',body, {headers:this.headers}).pipe(
      map<any,boolean>(
        resp => {
          if(!resp.valid) {
            this.svuotaLocalCart();
          }
          return resp.valid;
        }
      )
    );
  }

}
