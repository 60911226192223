<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1>{{'risultati_headertitle' | translate}}</h1>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li><a href="index.html">Home</a></li>
          <li class="active">{{'risultati_headertitle' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Page Header end -->
<div id="content">
  <section class="container">
    <div class="row">

    <!-- Side Panel
    ============================================= -->
      <aside class="col-md-3">
        <div class="bg-white shadow-md rounded p-3">
          <h3 class="text-5">{{'risultati_filtri' | translate}}</h3>
          <hr class="mx-n3">
          <div class="accordion accordion-alternate style-2 mt-n3" >
            <div class="card">
              <div class="card-header" id="departure">
                <h5 class="mb-0">
                  <button [attr.aria-expanded]="!isCollapsedOrari" aria-controls="toggleOrari" (click)="isCollapsedOrari = !isCollapsedOrari">{{'risultati_orariopartenza' | translate}}</button>
                </h5>
              </div>
              <div id="toggleOrari" aria-labelledby="toggleOrari" [collapse]="isCollapsedOrari" [isAnimated]="true">
                <div class="card-body">
                  <div class="slider-range-departure">
                    <ngx-slider [(value)]="filtroOraMin" [(highValue)]="filtroOraMax" [options]="filtriOrario" (userChangeEnd)="cercaSoluzioni();"></ngx-slider>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="price">
                <h5 class="mb-0">
                  <button [attr.aria-expanded]="!isCollapsedPrezzo" aria-controls="togglePrezzo" (click)="isCollapsedPrezzo = !isCollapsedPrezzo">{{'risultati_prezzo' | translate}}</button>
                </h5>
              </div>
              <div id="togglePrezzo" aria-labelledby="togglePrezzo" [collapse]="isCollapsedPrezzo" [isAnimated]="true">
                <div class="card-body">
                  <div class="slider-range-departure">
                    <ngx-slider [(value)]="filtroPrezzoMin" [(highValue)]="filtroPrezzoMax" [options]="filtriPrezzo" (userChangeEnd)="cercaSoluzioni();"></ngx-slider>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="mb-0">
                  <button [attr.aria-expanded]="!isCollapsedCambi" aria-controls="toggleCambi" (click)="isCollapsedCambi = !isCollapsedCambi">{{'risultati_numeromaxcambi' | translate}}</button>
                </h5>

              </div>
              <div id="toggleCambi" aria-labelledby="toggleCambi" [collapse]="isCollapsedCambi" [isAnimated]="true">
                <div class="card-body">
                  <div class="custom-control custom-radio">
                    <input type="radio" name="optCambio" [(ngModel)]="filtroCambio" [value]="0" class="custom-control-input" (change)="cercaSoluzioni()">
                    <label class="custom-control-label">Diretto</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" name="optCambio" [(ngModel)]="filtroCambio" [value]="1" class="custom-control-input" (change)="cercaSoluzioni()">
                    <label class="custom-control-label" >1 cambio</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" name="optCambio" [(ngModel)]="filtroCambio" [value]="2" class="custom-control-input" (change)="cercaSoluzioni()">
                    <label class="custom-control-label" >2 cambi</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" name="optCambio" [(ngModel)]="filtroCambio" [value]="3" class="custom-control-input" (change)="cercaSoluzioni()">
                    <label class="custom-control-label" >3+ cambi</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </aside><!-- Side Panel end -->

      <div class="col-md-9 mt-4 mt-md-0">
        <div class="bg-white shadow-md rounded py-4">
          <div class="mx-3 mb-3 text-center">
            <h2 class="text-6 mb-2">{{cittaPartenza}} <small class="mx-2"> - </small>{{cittaArrivo}}</h2>
            <h2 class="text-3 mb-4">{{dataPartenza | date:dateFormat}}</h2>
          </div>
          <div class="text-1 bg-light-3 border border-right-0 border-left-0 py-2 px-3">
            <div class="row">
              <div class="col col-sm-3"><span class="d-none d-sm-block">{{'risultati_operatore' | translate}}</span></div>
              <div class="col col-sm-2 text-center">{{'risultati_partenza' | translate}}</div>
              <div class="col-sm-2 text-center d-none d-sm-block">{{'risultati_durata' | translate}}</div>
              <div class="col col-sm-2 text-center">{{'risultati_arrivo' | translate}}</div>
              <div class="col col-sm-3 text-center d-none d-sm-block">{{'risultati_prezzo' | translate}} €</div>
            </div>
          </div>
          <div class="bus-list">
            <div class="bus-item" *ngFor="let corsa of listaCorseAndata; let i = index">
              <div class="row align-items-sm-center flex-row py-4 px-3">
                <div class="col col-sm-3">
                  <span class="text-3 text-dark operator-name cursor-pointer" (click)="isCollapsedInfoViaggioAndata[i].isCollapsed = !isCollapsedInfoViaggioAndata[i].isCollapsed" [attr.aria-expanded]="!isCollapsedInfoViaggioAndata[i].isCollapsed">La Valle Interlines <i class="fa fa-info-circle"></i></span>
                  <span class="text-1 text-muted d-block"></span>
                </div>
                <div class="col col-sm-2 text-center time-info"> <span class="text-4 text-dark">{{corsa.orarioPartenza}}</span> <small class="text-muted d-block">{{corsa.localitaPartenza}}</small> </div>
                <div class="col col-sm-2 text-center d-none d-sm-block time-info"> <span class="text-3 duration">{{corsa.durataText}}</span> <small class="text-muted d-block">{{corsa.tratte.length-1}} {{'risultati_cambi' | translate}}</small> </div>
                <div class="col col-sm-2 text-center time-info"> <span class="text-4 text-dark">{{corsa.orarioArrivo}}</span> <small class="text-muted d-block">{{corsa.localitaArrivo}}</small> </div>
                <div class="col-12 col-sm-3 align-self-center text-right text-sm-center mt-2 mt-sm-0">
                  <div class="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">{{corsa.prezzoTotale}}</div>
                  <div *ngIf="!corsa.viaggioPrenotabile" class="d-inline-block d-sm-block text-dark price mb-sm-1">Non disponibile</div>
                  <button *ngIf="corsa.viaggioPrenotabile" type="button" class="btn btn-sm btn-outline-primary shadow-none" (click)="selezionaViaggio(1,corsa)"  data-toggle="modal" data-target="#select-busseats"><i class="fa fa-ellipsis-h d-none d-sm-block d-lg-none" data-toggle="tooltip" title="{{'seleziona' | translate}}"></i> <span class="d-block d-sm-none d-lg-block">{{'risultati_prenota' | translate}} {{numAdulti+numBambini}} {{numAdulti+numBambini<2?('risultati_posto' | translate):('risultati_posti' | translate)}}</span></button> </div>
              </div>
              <div class="bg-white rounded p-3" [collapse]="isCollapsedInfoViaggioAndata[i].isCollapsed" [isAnimated]="true" >
                <hr class="mt-0">
                <div *ngFor="let tratta of corsa.tratte;let j = index" >
                <div class="position-relative pl-4">
                  <div class="location-brief-line">
                      <em class="location-brief-pickup"></em>
                      <em class="location-brief-dropoff"></em>
                  </div>
                  <h3 class="text-1 mb-1">{{tratta.cittaPartenza}} - {{tratta.orarioPartenza}}</h3>
                  <ul class="list-unstyled">
                    <li class="text-0 mb-1">{{tratta.localitaPartenza}}</li>
                    <li class="text-0 mb-1 text-underline cursor-pointer " (click)="isCollapsedInfoViaggioAndata[i].tratte[j] = !isCollapsedInfoViaggioAndata[i].tratte[j]" [attr.aria-expanded]="!isCollapsedInfoViaggioAndata[i].tratte[j]">{{tratta.fermate.length-1}} Fermate</li>
                  </ul>
                </div>
                <div class="position-relative pl-4" [collapse]="isCollapsedInfoViaggioAndata[i].tratte[j]" [isAnimated]="true" *ngFor="let fermata of tratta.fermate;let lastItem = last;">
                  <div class="location-brief-line" *ngIf="!lastItem">
                    <em class="location-brief-pickup"></em>
                    <em class="location-brief-dropoff"></em>
                </div>
                <h3 class="text-0 mb-1" *ngIf="!lastItem">{{fermata.citta}} ({{fermata.localita}})</h3>
                <ul class="list-unstyled" *ngIf="!lastItem">
                  <li class="text-0 mb-1"></li>
                </ul>
                </div>
                <div class="pl-4">
                  <h3 class="text-1 mb-1">{{tratta.cittaArrivo}} - {{tratta.orarioArrivo}}</h3>
                  <ul class="list-unstyled">
                    <li class="text-0 mb-1">{{tratta.localitaArrivo}}</li>
                  </ul>
                </div>
              </div>

              </div>
            </div>
            <div class="bus-item" *ngIf="this.biglietto.viaggioAndata!=null" >
              <div class="row align-items-sm-center flex-row py-4 px-3">
                <div class="col col-sm-3"> <span class="text-3 text-dark operator-name">La Valle Interlines</span> <span class="text-1 text-muted d-block"></span> </div>
                <div class="col col-sm-2 text-center time-info"> <span class="text-4 text-dark">{{this.biglietto.viaggioAndata.orarioPartenza}}</span> <small class="text-muted d-block">{{this.biglietto.viaggioAndata.cittaPartenza}}</small> </div>
                <div class="col col-sm-2 text-center d-none d-sm-block time-info"> <span class="text-3 duration">{{this.biglietto.viaggioAndata.durataText}}</span> <small class="text-muted d-block">{{this.biglietto.viaggioAndata.tratte.length-1}} {{'risultati_cambi' | translate}}</small> </div>
                <div class="col col-sm-2 text-center time-info"> <span class="text-4 text-dark">{{this.biglietto.viaggioAndata.orarioArrivo}}</span> <small class="text-muted d-block">{{this.biglietto.viaggioAndata.cittaArrivo}}</small> </div>
                <div class="col-12 col-sm-3 align-self-center text-right text-sm-center mt-2 mt-sm-0">
                  <div class="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">{{this.biglietto.viaggioAndata.prezzoTotale}}</div>
                  <button type="button" class="btn btn-sm btn-outline-primary shadow-none" (click)="rimuoviViaggio(1)"  data-toggle="modal" data-target="#select-busseats"><i class="fas fa-ellipsis-h d-none d-sm-block d-lg-none" data-toggle="tooltip" title="{{'seleziona' | translate}}"></i> <span class="d-block d-sm-none d-lg-block">{{'risultati_rimuoviviaggio' | translate}}</span></button> </div>
              </div>
            </div>
            <div class="bus-item" *ngIf="listaCorseAndata.length==0 && this.biglietto.viaggioAndata==null" >
              <h2 class="text-3 ml-2 mr-2 mt-2 mb-2 text-center" *ngIf="!errorTimeout">{{'risultati_nessunrisultato' | translate}}</h2>
              <h2 class="text-3 ml-2 mr-2 mt-2 mb-2 text-center" *ngIf="errorTimeout" >{{'generic_servizionondisponibile' | translate}}</h2>
            </div>
          </div>

        </div>
        <div class="bg-white shadow-md rounded py-4 mt-4 mt-md-4" *ngIf="isAndataRitorno && this.biglietto.viaggioAndata!=null">
          <div class="mx-3 mb-3 text-center">
            <h2 class="text-6 mb-2">{{cittaArrivo}} <small class="mx-2"> - </small>{{cittaPartenza}}</h2>
            <h2 class="text-3 mb-4">{{dataRitorno | date:dateFormat}}</h2>
          </div>
          <div class="text-1 bg-light-3 border border-right-0 border-left-0 py-2 px-3">
            <div class="row">
              <div class="col col-sm-3"><span class="d-none d-sm-block">{{'risultati_operatore' | translate}}</span></div>
              <div class="col col-sm-2 text-center">{{'risultati_partenza' | translate}}</div>
              <div class="col-sm-2 text-center d-none d-sm-block">{{'risultati_durata' | translate}}</div>
              <div class="col col-sm-2 text-center">{{'risultati_arrivo' | translate}}</div>
              <div class="col col-sm-3 text-center d-none d-sm-block">{{'risultati_prezzo' | translate}} €</div>
            </div>
          </div>

          <div class="bus-list" >
            <div class="bus-item" *ngFor="let corsa of listaCorseRitorno; let i = index">
              <div class="row align-items-sm-center flex-row py-4 px-3">
                <div class="col col-sm-3">
                  <span class="text-3 text-dark operator-name cursor-pointer" (click)="isCollapsedInfoViaggioRitorno[i].isCollapsed = !isCollapsedInfoViaggioRitorno[i].isCollapsed" [attr.aria-expanded]="!isCollapsedInfoViaggioAndata[i].isCollapsed">La Valle Interlines <i class="fa fa-info-circle"></i></span>
                  <span class="text-1 text-muted d-block"></span>
                </div>
                <div class="col col-sm-2 text-center time-info"> <span class="text-4 text-dark">{{corsa.orarioPartenza}}</span> <small class="text-muted d-block">{{corsa.localitaPartenza}}</small> </div>
                <div class="col col-sm-2 text-center d-none d-sm-block time-info"> <span class="text-3 duration">{{corsa.durataText}}</span> <small class="text-muted d-block">{{corsa.tratte.length-1}} {{'risultati_cambi' | translate}}</small> </div>
                <div class="col col-sm-2 text-center time-info"> <span class="text-4 text-dark">{{corsa.orarioArrivo}}</span> <small class="text-muted d-block">{{corsa.localitaArrivo}}</small> </div>
                <div class="col-12 col-sm-3 align-self-center text-right text-sm-center mt-2 mt-sm-0">
                  <div class="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">{{corsa.prezzoTotale}}</div>
                  <div *ngIf="!corsa.viaggioPrenotabile" class="d-inline-block d-sm-block text-dark price mb-sm-1">Non disponibile</div>
                  <button *ngIf="corsa.viaggioPrenotabile" type="button" class="btn btn-sm btn-outline-primary shadow-none" (click)="selezionaViaggio(2,corsa)"  data-toggle="modal" data-target="#select-busseats"><i class="fa fa-ellipsis-h d-none d-sm-block d-lg-none" data-toggle="tooltip" title="{{'seleziona' | translate}}"></i> <span class="d-block d-sm-none d-lg-block">{{'risultati_prenota' | translate}} {{numAdulti+numBambini}} {{numAdulti+numBambini<2?('risultati_posto' | translate):('risultati_posti' | translate)}}</span></button> </div>
              </div>
              <div class="bg-white rounded p-3" [collapse]="isCollapsedInfoViaggioRitorno[i].isCollapsed" [isAnimated]="true" >
                <hr class="mt-0">
                <div *ngFor="let tratta of corsa.tratte;let j = index" >
                <div class="position-relative pl-4">
                  <div class="location-brief-line">
                      <em class="location-brief-pickup"></em>
                      <em class="location-brief-dropoff"></em>
                  </div>
                  <h3 class="text-1 mb-1">{{tratta.cittaPartenza}} - {{tratta.orarioPartenza}}</h3>
                  <ul class="list-unstyled">
                    <li class="text-0 mb-1">{{tratta.localitaPartenza}}</li>
                    <li class="text-0 mb-1 text-underline cursor-pointer " (click)="isCollapsedInfoViaggioRitorno[i].tratte[j] = !isCollapsedInfoViaggioRitorno[i].tratte[j]" [attr.aria-expanded]="!isCollapsedInfoViaggioRitorno[i].tratte[j]">{{tratta.fermate.length-1}} Fermate</li>
                  </ul>
                </div>
                <div class="position-relative pl-4" [collapse]="isCollapsedInfoViaggioRitorno[i].tratte[j]" [isAnimated]="true" *ngFor="let fermata of tratta.fermate;let lastItem = last;">
                  <div class="location-brief-line" *ngIf="!lastItem">
                    <em class="location-brief-pickup"></em>
                    <em class="location-brief-dropoff"></em>
                </div>
                <h3 class="text-0 mb-1" *ngIf="!lastItem">{{fermata.citta}} ({{fermata.localita}})</h3>
                <ul class="list-unstyled" *ngIf="!lastItem">
                  <li class="text-0 mb-1"></li>
                </ul>
                </div>
                <div class="pl-4">
                  <h3 class="text-1 mb-1">{{tratta.cittaArrivo}} - {{tratta.orarioArrivo}}</h3>
                  <ul class="list-unstyled">
                    <li class="text-0 mb-1">{{tratta.localitaArrivo}}</li>
                  </ul>
                </div>
              </div>

              </div>
            </div>
            <div class="bus-item" *ngIf="listaCorseRitorno.length==0" >
              <h2 class="text-3 ml-2 mr-2 mt-2 mb-2 text-center" *ngIf="!errorTimeout">{{'risultati_nessunrisultato' | translate}}</h2>
              <h2 class="text-3 ml-2 mr-2 mt-2 mb-2 text-center" *ngIf="errorTimeout" >{{'generic_servizionondisponibile' | translate}}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div><!-- Content end -->
