import { environment } from 'src/environments/environment';
import { LOCALE_ID, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
//import { registerLocaleData } from '@angular/common';
//import localeIt from '@angular/common/locales/it';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { Error404Component } from './error404/error404.component';
//import {  NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { SearchresultsComponent } from './searchresults/searchresults.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CheckoutComponent } from './checkout/checkout.component';
import { LogoutComponent } from './logout/logout.component';
import { PrenotazioneExpiredComponent } from './prenotazione-expired/prenotazione-expired.component';
import { ContactComponent } from './contact/contact.component';
import { SupportComponent } from './support/support.component';
import { NovitaComponent } from './novita/novita.component';
import { AziendaComponent } from './azienda/azienda.component';
import { DEFAULT_TIMEOUT, TimeoutInterceptorService } from './services/interceptor/timeout-interceptor.service';
import { ServizioNonDisponibileComponent } from './servizio-non-disponibile/servizio-non-disponibile.component';
import { CheckMinDirective } from './services/validators/check-min';
import { CheckMaxDirective } from './services/validators/check-max';
import { PagamentoComponent } from './pagamento/pagamento.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AgmCoreModule } from '@agm/core';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { InputOnlyIntegerDirective } from './services/directives/input-only-integer.directive';
import { DetailNoleggioComponent } from './detail-noleggio/detail-noleggio.component';
import { AgmDirectionModule } from 'agm-direction';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { PasswordDimenticataComponent } from './password-dimenticata/password-dimenticata.component';
import { NovitaSingleComponent } from './novita-single/novita-single.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { CookiepageComponent } from './cookiepage/cookiepage.component';
import { PrivacyComponent } from './privacy/privacy.component';


//registerLocaleData(localeIt,'it')
defineLocale('it', itLocale);

/*
export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}*/
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig:NgcCookieConsentConfig = {
    "cookie": {
      "domain": environment.dominio
    },
    "position": "bottom-right",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#000000",
        "text": "#ffffff",
        "link": "#ffffff"
      },
      "button": {
        "background": "#cc0000",
        "text": "#ffffff",
        "border": "transparent"
      }
    },
    "type": "info",
    "content": {
      "header": "Rispettiamo la vostra privacy",
      "message": "Questo sito web utilizza i cookie per assicurarti di ottenere la migliore esperienza sul nostro sito web.",
      "dismiss": "Accetta",
      "allow": "Consenti cookies",
      "deny": "Rifiuta",
      "link": "Per saperne di più",
      "policy": "Cookie Policy",
      "href":environment.urlCookiePrivacy
    }
  };


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    Error404Component,
    LoginComponent,
    SearchresultsComponent,
    CheckoutComponent,
    LogoutComponent,
    PrenotazioneExpiredComponent,
    ContactComponent,
    SupportComponent,
    NovitaComponent,
    AziendaComponent,
    ServizioNonDisponibileComponent,
    CheckMinDirective,
    CheckMaxDirective,
    PagamentoComponent,
    InputOnlyIntegerDirective,
    DetailNoleggioComponent,
    ConfirmRegistrationComponent,
    PasswordDimenticataComponent,
    NovitaSingleComponent,
    PrivacyComponent,
    CookiepageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    //NgbModule,
    //NgbNavModule,
    BrowserAnimationsModule,
    TabsModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    NgxSliderModule,
    PopoverModule.forRoot(),
    ButtonsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ["places"]
    }),
    AgmDirectionModule,
    CarouselModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptorService, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 20000 }]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
