import { UserInfo } from './../models/userInfo';
import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  bsModalRef: BsModalRef;
  isCollapsedMobileMenu = true;

  LoginStatus$ : Observable<UserInfo>;
  userInfo : UserInfo = new UserInfo();

  constructor(private translateService:TranslateService,private localeService:BsLocaleService,private modalService: BsModalService,private authService: AuthService) {

  }

  ngOnInit(): void {
    this.LoginStatus$ = this.authService.isLoggedIn;
    this.LoginStatus$.subscribe((user) => {
      //console.log('handler navbar cambiamento loginstatus', user);
      if(!user) user=new UserInfo();
      this.userInfo=user;
    });
  }

  public useLanguage(lang: string): void {
    this.translateService.use(lang);
    this.localeService.use(lang);
  }

  public openLoginComponent(){
    const initialState = {

    };
    this.bsModalRef = this.modalService.show(LoginComponent, {initialState});

  }

  logout() {
    this.authService.logout();
  }

  onMenuItemHidden(): void {
    console.log('Dropdown is hidden');
  }
  onMenuItemShown(): void {
    console.log('Dropdown is shown');
  }
  isMenuItemOpenChange(): void {
    console.log('Dropdown state is changed');
  }
}
