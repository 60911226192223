import { ProfileService } from './../services/profile.service';
import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email:string ='';
  password:string ='';

  nome = '';
  cognome = '';
  emailSignup = '';
  passwordSignup = '';
  dataNascita = '';
  agree = false;

  erroreAutenticazione=false;
  erroreRegistrazione=false;
  erroreEmailPresente=false;
  messaggioRegistrazioneOk=false;

  constructor(public router:Router,public bsModalRef: BsModalRef,private spinner: NgxSpinnerService,private authService:AuthService,private profileService:ProfileService) { }

  ngOnInit(): void {

  }

  login(){
    this.spinner.show();
    this.authService.authenticate(this.email,this.password).subscribe(
      res => {
        this.spinner.hide();
        this.erroreAutenticazione=false;
        this.bsModalRef.hide();
      },
      error=>{
        this.spinner.hide();
        this.erroreAutenticazione=true;
      }
    )
  }
  goToPasswordDimenticata(){
    this.router.navigate(['/password-dimenticata']);
    this.bsModalRef.hide();
  }

  signup(){
    this.messaggioRegistrazioneOk=false;
    this.erroreRegistrazione=false;
    this.erroreEmailPresente=false;

    this.spinner.show();
    this.profileService.registrazioneUtente(this.nome,this.cognome,this.emailSignup,this.passwordSignup,this.dataNascita).subscribe(
      res => {
        this.spinner.hide();
        this.erroreRegistrazione=false;
        if(res.errore.codice==4) {
          this.erroreEmailPresente=true;
          return;
        }
        this.messaggioRegistrazioneOk=true;
      },
      error=>{
        this.spinner.hide();
        this.erroreRegistrazione=true;
      }
    )
  }

}
