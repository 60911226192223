<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1>{{'dettaglionoleggio_headertitle' | translate}}</h1>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li><a href="index.html">Home</a></li>
          <li class="active">{{'dettaglionoleggio_headertitle' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Page Header end -->
  <!-- Content
============================================= -->
<div id="content">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="bg-white shadow-md rounded h-100 p-3" style="min-height: 500px;">
          <agm-map class="h-100 w-100" [latitude]="41.858610" [longitude]="12.490647" *ngIf="richiestaNoleggio && richiestaNoleggio.listViaggi.length>0" >
            <agm-direction [origin]="richiestaNoleggio.listViaggi[0].cittaPartenza" [destination]="richiestaNoleggio.listViaggi[0].cittaArrivo">
            </agm-direction>
          </agm-map>
        </div>
      </div>
      <div class="col-md-6 mt-4 mt-md-0">
        <div class="bg-white shadow-md rounded p-4">
          <h2 class="text-6 mb-4">{{'dettaglionoleggio_ituoidettagli' | translate}}</h2>
          <hr class="mx-n4 mb-4">
          <h2 class="text-3 mb-2">{{'dettaglionoleggio_numeropasseggeri' | translate}}: {{richiestaNoleggio.numeroPasseggeri}}</h2>
          <h2 class="text-3 mb-4">{{'dettaglionoleggio_tipobus' | translate}}: {{richiestaNoleggio.tipoBus}}</h2>
          <div *ngFor="let viaggio of richiestaNoleggio.listViaggi"  >
              <div class="position-relative pl-4">
                <div class="location-brief-line">
                    <em class="location-brief-pickup"></em>
                      <em class="location-brief-dropoff"></em>
                  </div>
                  <h3 class="text-2 mb-2">{{viaggio.cittaPartenza}}</h3>
                  <ul class="list-unstyled">
                    <li class="mb-1">{{viaggio.dataPartenza}} h.{{viaggio.orarioPartenza}}</li>
                  </ul>
                </div>
                <div class="pl-4">
                  <h3 class="text-2 mb-2">{{viaggio.cittaArrivo}}</h3>
                  <ul class="list-unstyled">
                    <li class="mb-1">{{viaggio.dataArrivo}} h.{{viaggio.orarioArrivo}}</li>
                  </ul>
                </div>
                <h3 class="text-2 mb-4 text-center">{{'dettaglionoleggio_kmprevisti' | translate}}: {{viaggio.kmtratta}} - {{'dettaglionoleggio_durata' | translate}} {{viaggio.durata}}</h3>
              <br/>
          </div>
          <form (ngSubmit)="formRichiestaNoleggio.valid && inviaRichiesta()" #formRichiestaNoleggio=ngForm>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 ml-0 form-group">
                <label for="txtCliente">{{'dettaglionoleggio_richiestaeffettuatada' | translate}}</label>
                <input type="text" name="cliente" required [(ngModel)]="cliente" class="form-control">
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 ml-0 form-group">
                <label for="txtTel">{{'dettaglionoleggio_recapito' | translate}}</label>
                <input type="text" name="telefono" required [(ngModel)]="telefono" class="form-control">
              </div>
              <div class="col-12 ml-0 form-group">
                <label for="txtTel">{{'dettaglionoleggio_email' | translate}}</label>
                <input type="text" name="email" required [(ngModel)]="email" class="form-control">
              </div>
              <div class="col-12 ml-0 form-group">
                <label for="txtNote">{{'dettaglionoleggio_note' | translate}}</label>
                <textarea name="note" class="form-control" rows="5" [(ngModel)]="note" placeholder=""></textarea>
              </div>
              <div class="col-12 ml-0 form-group">
                <button class="btn btn-primary btn-block mt-2" [disabled]="formRichiestaNoleggio.invalid">{{'dettaglionoleggio_inviarichiesta' | translate}}</button>
              </div>
              <div class="col-12 ml-0 form-group">
                <div class="col-lg-12 text-center" *ngIf="esitoRichiesta==1">
                  <p class="text-success text-12 line-height-07 mt-3"><i class="fa fa-check-circle"></i></p>
                    <h2 class="text-6">{{'dettaglionoleggio_richiestainoltrata' | translate}}</h2>
                  <p class="lead">{{'dettaglionoleggio_richiestainoltratoesito' | translate}}</p>
                  <a class="pr-0" title="Ritorna alla Home" [routerLink]="['/home']">{{'dettaglionoleggio_tornaallahome' | translate}}</a>
                </div>
                <div class="text-center bg-primary-color-disabled p-2 rounded" *ngIf="esitoRichiesta==2" ><span class="text-light "><strong>{{'checkout_spiacenti' | translate}}, </strong> {{'dettaglionoleggio_richiestako' | translate}}</span></div>
              </div>

            </div>
          </form>
      </div>
    </div>
  </div>
</div><!-- Content end -->
