<div id="content">
  <div class="hero-wrap py-4 py-md-5">
    <div class="hero-mask opacity-6 bg-primary"></div>
    <div class="hero-bg" style="background-image:url('./assets/images/bg/img-lvl.jpg');"></div>
    <div class="hero-content py-0 py-lg-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="position-relative px-4 pt-3 pb-4">
              <div class="hero-mask opacity-8 bg-dark rounded"></div>
              <div class="hero-content">
                <!-- Tabs -->
                  <div class="nav-fill">
                    <tabset>
                      <!--
                      <tab heading="Bus" customClass="style-4">
                          <form id="bookingTrain" class="search-input-line" (ngSubmit)="formCercaBus.valid && cercaSoluzioni()" #formCercaBus=ngForm>
                            <div class="row">
                              <div class="col-12 form-group">
                                <ng-select name="txtCittaPartenza" class="form-control" required placeholder="{{'home_partenza' | translate}}" [(ngModel)]="cittaPartenza" (change)="refreshDestinazioni()" notFoundText="{{'home_nessunacittatrovata' | translate}}" >
                                  <ng-option *ngFor="let citta of partenze" [value]="citta">{{citta.citta}}</ng-option>
                                </ng-select>
                                <span class="icon-inside"><i class="fa fa-map-marker"></i></span>
                              </div>
                              <div class="col-12 form-group">
                                <ng-select name="txtCittaArrivo" class="form-control" required placeholder="{{'home_destinazione' | translate}}" [(ngModel)]="cittaArrivo" notFoundText="{{'home_nessunacittatrovata' | translate}}">
                                  <ng-option *ngFor="let citta of destinazioni" [value]="citta">{{citta.citta}}</ng-option>
                                </ng-select>
                                <span class="icon-inside"><i class="fa fa-map-marker"></i></span>
                              </div>
                              <div class="col-6 form-group">
                                <input name="dataPartenza" placeholder="{{'home_data_andata' | translate}}" type="text" class="form-control" bsDatepicker #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [(ngModel)]="dataPartenza" required>
                                <div class="input-group-append">
                                  <span class="icon-inside">
                                    <i #buttonDateEl class="fa fa-calendar"></i>
                                  </span>
                                </div>
                              </div>
                              <div class="col-6 form-group">
                                <input name="dataRitorno" placeholder="{{'home_data_ritorno' | translate}}" type="text" class="form-control" bsDatepicker #dpr="bsDatepicker" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [(ngModel)]="dataRitorno">
                                <div class="input-group-append">
                                  <span class="icon-inside">
                                    <i #buttonDateEl2 class="fa fa-calendar"></i>
                                  </span>
                                </div>
                              </div>
                              <div class="col-12 travellers-class form-group">
                                <input name="txtPlaceholderPosti" type="text" class="travellers-class-input form-control" name="train-travellers-class" placeholder="{{getPlaceholderPosti()}}" (click)="isCollapsedSeatNumber = !isCollapsedSeatNumber" [attr.aria-expanded]="!isCollapsedSeatNumber" />
                                <span class="icon-inside"><i class="fa fa-caret-down"></i></span>


                                <div id="collapseSeatNumber" class="travellers-dropdown" [collapse]="isCollapsedSeatNumber" [isAnimated]="true">
                                  <div class="row align-items-center">
                                    <div class="col-sm-7">
                                      <p class="mb-sm-0">{{'home_adulti' | translate}} <small class="text-muted">(12+ yrs)</small></p>
                                    </div>
                                    <div class="col-sm-5">
                                      <div class="qty input-group">
                                        <div class="input-group-prepend">
                                          <button type="button" class="btn bg-light-4" data-value="decrease" data-target="#trainAdult-travellers" data-toggle="spinner" (click)="numeroAdulti==0?0:numeroAdulti=numeroAdulti-1">-</button>
                                        </div>
                                        <input name="txtNumAdulti" type="text" data-ride="spinner" id="trainAdult-travellers" class="qty-spinner form-control" value="1" [(ngModel)]="numeroAdulti" readonly>
                                        <div class="input-group-append">
                                          <button type="button" class="btn bg-light-4" data-value="increase" data-target="#trainAdult-travellers" data-toggle="spinner" (click)="numeroAdulti=numeroAdulti+1">+</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr class="my-2">
                                  <div class="row align-items-center">
                                    <div class="col-sm-7">
                                      <p class="mb-sm-0">{{'home_bambini' | translate}} <small class="text-muted">(0-12 yrs)</small></p>
                                    </div>
                                    <div class="col-sm-5">
                                      <div class="qty input-group">
                                        <div class="input-group-prepend">
                                          <button type="button" class="btn bg-light-4" data-value="decrease" data-target="#trainChildren-travellers" data-toggle="spinner" (click)="numeroBambini==0?0:numeroBambini=numeroBambini-1">-</button>
                                        </div>
                                        <input type="text" name="txtNumBambini" data-ride="spinner" id="trainChildren-travellers" class="qty-spinner form-control" value="0" [(ngModel)]="numeroBambini" readonly>
                                        <div class="input-group-append">
                                          <button type="button" class="btn bg-light-4" data-value="increase" data-target="#trainChildren-travellers" data-toggle="spinner" (click)="numeroBambini=numeroBambini+1" >+</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr class="my-2">
                                  <button class="btn btn-primary btn-block submit-done" type="button" (click)="isCollapsedSeatNumber = !isCollapsedSeatNumber" >Conferma</button>
                                </div>
                              </div>
                            </div>
                            <button class="btn btn-primary btn-block mt-2" [disabled]="formCercaBus.invalid">{{'home_cerca' | translate}}</button>
                          </form>
                      </tab>
                      -->
                      <tab heading="Richiesta Noleggio" customClass="style-4">
                        <form id="bookingNoleggio" class="search-input-line" (ngSubmit)="formCercaNoleggio.valid && continuaRichiestaNoleggio()" #formCercaNoleggio=ngForm>
                          <div class="row">
                            <div class="col-12 form-group mt-3 mb-0 text-center">
                              <div class="d-flex flex-column d-xl-flex flex-xl-row justify-content-between btn-group-sm" name="tipoNoleggio" btnRadioGroup [(ngModel)]="tipoNoleggio">
                                <label class="btn btn-outline-primary btn-sm shadow-none text-grey border-grey" btnRadio="4" popover="{{'home_andataritornoinfo' | translate}}" triggers="mouseenter:mouseleave">{{'home_andataritorno' | translate}}</label>
                                <label class="btn btn-outline-primary btn-sm shadow-none text-grey border-grey" btnRadio="2" popover="{{'home_soloandatainfo' | translate}}" triggers="mouseenter:mouseleave">{{'home_soloandata' | translate}}</label>
                                <label class="btn btn-outline-primary btn-sm shadow-none text-grey border-grey" btnRadio="3" popover="{{'home_fermatemultipleinfo' | translate}}" triggers="mouseenter:mouseleave">{{'home_fermatemultiple' | translate}}</label>
                                <label class="btn btn-outline-primary btn-sm shadow-none text-grey border-grey" btnRadio="1" popover="{{'home_andataritornoseparatainfo' | translate}}" triggers="mouseenter:mouseleave">{{'home_andataritornoseparata' | translate}}</label>
                              </div>
                            </div>
                            <div class="col-12 form-group">
                              <!--<input name="indirizzoPartenza" required [(ngModel)]="indirizzoPartenza" typeaheadOptionField="description" [typeahead]="indirizziPartenzaResults$" [typeaheadAsync]="true" typeaheadWaitMs="500" typeaheadMinLength="4" class="form-control" placeholder="{{'home_indirizzopartenza' | translate}}" container="body">-->
                              <input type="text" name="indirizzoPartenza" required [(ngModel)]="indirizzoPartenza" placeholder="{{'home_indirizzopartenza' | translate}}" autocorrect="off" autocapitalize="off" spellcheck="off" class="form-control" #indirizzoPartenzaElement>
                              <span class="icon-inside"><i class="fa fa-map-marker"></i></span>
                            </div>
                            <div class="col-12 form-group">
                              <input type="text" name="indirizzoArrivo" required [(ngModel)]="indirizzoArrivo" placeholder="{{'home_indirizzodestinazione' | translate}}" autocorrect="off" autocapitalize="off" spellcheck="off" class="form-control" #indirizzoDestinazioneElement>
                              <span class="icon-inside"><i class="fa fa-map-marker"></i></span>
                            </div>
                            <div class="col-8 form-group">
                              <input name="dataPartenzaNoleggio" placeholder="{{'home_data_andata' | translate}}" type="text" class="form-control" bsDatepicker #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="today"  [(ngModel)]="dataPartenzaNoleggio" required>
                              <div class="input-group-append">
                                <span class="icon-inside">
                                  <i #buttonDateEl class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                            <div class="col-4 form-group">
                              <ng-select name="txtOraPartenzaNoleggio" class="form-control pb-0" required placeholder="{{'home_orapartenza' | translate}}" [(ngModel)]="oraPartenzaNoleggio">
                                <ng-option value="00:00">00:00</ng-option>
                                <ng-option value="00:30">00:30</ng-option>
                                <ng-option value="01:00">01:00</ng-option>
                                <ng-option value="01:30">01:30</ng-option>
                                <ng-option value="02:00">02:00</ng-option>
                                <ng-option value="02:30">02:30</ng-option>
                                <ng-option value="03:00">03:00</ng-option>
                                <ng-option value="03:30">03:30</ng-option>
                                <ng-option value="04:00">04:00</ng-option>
                                <ng-option value="04:30">04:30</ng-option>
                                <ng-option value="05:00">05:00</ng-option>
                                <ng-option value="05:30">05:30</ng-option>
                                <ng-option value="06:00">06:00</ng-option>
                                <ng-option value="06:30">06:30</ng-option>
                                <ng-option value="07:00">07:00</ng-option>
                                <ng-option value="07:30">07:30</ng-option>
                                <ng-option value="08:00">08:00</ng-option>
                                <ng-option value="08:30">08:30</ng-option>
                                <ng-option value="09:00">09:00</ng-option>
                                <ng-option value="09:30">09:30</ng-option>
                                <ng-option value="10:00">10:00</ng-option>
                                <ng-option value="10:30">10:30</ng-option>
                                <ng-option value="11:00">11:00</ng-option>
                                <ng-option value="11:30">11:30</ng-option>
                                <ng-option value="12:00">12:00</ng-option>
                                <ng-option value="12:30">12:30</ng-option>
                                <ng-option value="13:00">13:00</ng-option>
                                <ng-option value="13:30">13:30</ng-option>
                                <ng-option value="14:00">14:00</ng-option>
                                <ng-option value="14:30">14:30</ng-option>
                                <ng-option value="15:00">15:00</ng-option>
                                <ng-option value="15:30">15:30</ng-option>
                                <ng-option value="16:00">16:00</ng-option>
                                <ng-option value="16:30">16:30</ng-option>
                                <ng-option value="17:00">17:00</ng-option>
                                <ng-option value="17:30">17:30</ng-option>
                                <ng-option value="18:00">18:00</ng-option>
                                <ng-option value="18:30">18:30</ng-option>
                                <ng-option value="19:00">19:00</ng-option>
                                <ng-option value="19:30">19:30</ng-option>
                                <ng-option value="20:00">20:00</ng-option>
                                <ng-option value="20:30">20:30</ng-option>
                                <ng-option value="21:00">21:00</ng-option>
                                <ng-option value="21:30">21:30</ng-option>
                                <ng-option value="22:00">22:00</ng-option>
                                <ng-option value="22:30">22:30</ng-option>
                                <ng-option value="23:00">23:00</ng-option>
                                <ng-option value="23:30">23:30</ng-option>
                              </ng-select>
                              <span class="icon-inside"><i class="fa fa-clock"></i></span>
                            </div>
                            <div class="col-8 form-group" *ngIf="tipoNoleggio!=='2'">
                              <input name="dataRitornoNoleggio" [required]="tipoNoleggio!=='2'" placeholder="{{'home_data_ritorno' | translate}}" type="text" class="form-control" bsDatepicker #dpr="bsDatepicker" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="today" [(ngModel)]="dataRitornoNoleggio">
                              <div class="input-group-append">
                                <span class="icon-inside">
                                  <i #buttonDateEl2 class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                            <div class="col-4 form-group" *ngIf="tipoNoleggio!=='2'">
                              <ng-select name="txtOraRitornoNoleggio" class="form-control pb-0" [required]="tipoNoleggio!=='2'" placeholder="{{'home_oraritorno' | translate}}" [(ngModel)]="oraRitornoNoleggio">
                                <ng-option value="00:00">00:00</ng-option>
                                <ng-option value="00:30">00:30</ng-option>
                                <ng-option value="01:00">01:00</ng-option>
                                <ng-option value="01:30">01:30</ng-option>
                                <ng-option value="02:00">02:00</ng-option>
                                <ng-option value="02:30">02:30</ng-option>
                                <ng-option value="03:00">03:00</ng-option>
                                <ng-option value="03:30">03:30</ng-option>
                                <ng-option value="04:00">04:00</ng-option>
                                <ng-option value="04:30">04:30</ng-option>
                                <ng-option value="05:00">05:00</ng-option>
                                <ng-option value="05:30">05:30</ng-option>
                                <ng-option value="06:00">06:00</ng-option>
                                <ng-option value="06:30">06:30</ng-option>
                                <ng-option value="07:00">07:00</ng-option>
                                <ng-option value="07:30">07:30</ng-option>
                                <ng-option value="08:00">08:00</ng-option>
                                <ng-option value="08:30">08:30</ng-option>
                                <ng-option value="09:00">09:00</ng-option>
                                <ng-option value="09:30">09:30</ng-option>
                                <ng-option value="10:00">10:00</ng-option>
                                <ng-option value="10:30">10:30</ng-option>
                                <ng-option value="11:00">11:00</ng-option>
                                <ng-option value="11:30">11:30</ng-option>
                                <ng-option value="12:00">12:00</ng-option>
                                <ng-option value="12:30">12:30</ng-option>
                                <ng-option value="13:00">13:00</ng-option>
                                <ng-option value="13:30">13:30</ng-option>
                                <ng-option value="14:00">14:00</ng-option>
                                <ng-option value="14:30">14:30</ng-option>
                                <ng-option value="15:00">15:00</ng-option>
                                <ng-option value="15:30">15:30</ng-option>
                                <ng-option value="16:00">16:00</ng-option>
                                <ng-option value="16:30">16:30</ng-option>
                                <ng-option value="17:00">17:00</ng-option>
                                <ng-option value="17:30">17:30</ng-option>
                                <ng-option value="18:00">18:00</ng-option>
                                <ng-option value="18:30">18:30</ng-option>
                                <ng-option value="19:00">19:00</ng-option>
                                <ng-option value="19:30">19:30</ng-option>
                                <ng-option value="20:00">20:00</ng-option>
                                <ng-option value="20:30">20:30</ng-option>
                                <ng-option value="21:00">21:00</ng-option>
                                <ng-option value="21:30">21:30</ng-option>
                                <ng-option value="22:00">22:00</ng-option>
                                <ng-option value="22:30">22:30</ng-option>
                                <ng-option value="23:00">23:00</ng-option>
                                <ng-option value="23:30">23:30</ng-option>
                              </ng-select>
                              <span class="icon-inside"><i class="fa fa-clock"></i></span>
                            </div>
                            <div class="col-4 form-group">
                              <!--<input name="indirizzoPartenza" required [(ngModel)]="indirizzoPartenza" typeaheadOptionField="description" [typeahead]="indirizziPartenzaResults$" [typeaheadAsync]="true" typeaheadWaitMs="500" typeaheadMinLength="4" class="form-control" placeholder="{{'home_indirizzopartenza' | translate}}" container="body">-->
                              <input type="number" inputOnlyInteger name="numeroPersoneNoleggio" min="8" required [(ngModel)]="numeroPersoneNoleggio" placeholder="{{'home_numeropasseggeri' | translate}}" class="form-control">
                              <span class="icon-inside"><i class="fa fa-male"></i></span>
                            </div>
                            <div class="col-8 form-group">
                              <button class="btn btn-primary btn-block mt-2" [disabled]="formCercaNoleggio.invalid">{{'home_continuarichiesta' | translate}}</button>
                            </div>
                          </div>

                        </form>
                      </tab>
                  </tabset>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt-5 mt-lg-0">
            <h2 class="text-9 font-weight-600 text-light">{{infohome.titolo}}</h2>
            <p class="lead mb-4 text-light" [innerHTML]="infohome.descrizione"></p>
            <div class="row">
              <div class="col-12">
                <div class="featured-box style-3 mb-4" *ngFor="let homebox of listBoxHome; let i = index">
                  <div class="featured-box-icon border rounded-circle text-light"> <i class="{{listBoxHome[i].icona}}"></i></div>
                  <h3 class="text-light">{{homebox.titolo}}</h3>
                  <p class="text-light opacity-8" [innerHTML]="homebox.descrizione"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section bg-white shadow-md">
    <div class="container">

      <!-- Banner
      ============================================= -->
      <div class="row">
        <div class="col-md-12">
          <carousel [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]= "singleSlideOffset"  class="carosello-multi" [noPause]="false">
            <slide *ngFor="let slide of slides; let index=index"  class="cursor-pointer">
              <img [src]="slide.image"  alt="image slide" style="display: block; width: 100%;" class="img-fluid rounded">
              <div class="carousel-caption text-left p-2"  >
                <p class="text-4 font-weight-500">{{carouselTitle[index]}}</p>
                <p class="text-1 font-weight-400">{{carouselDes[index]}}</p>
              </div>
            </slide>
          </carousel>
      </div>
    </div>
      <!-- Banner end -->

        <!-- Popular Routes
    ============================================= -->
    <!--
    <h2 class="text-9 font-weight-600 text-center mt-5">Tanti modi per Viaggiare</h2>
    <p class="lead text-dark text-center mb-5">Autobus da 8 a 82 posti, Noleggio auto con conducente</p>
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="accordion accordion-alternate popularRoutes mx-lg-2" id="popularRoutes">
          <div class="card">
            <div class="card-header" id="one">
              <h5 class="collapsed mb-0" (click)="isCollapsedDestinazione1 = !isCollapsedDestinazione1" [attr.aria-expanded]="!isCollapsedDestinazione1" aria-controls="collapseDestinazione1"> Gite Fuoriporta
                <span class="nav">
                  <a href="#">HOTELS</a><a href="#">FLIGHTS</a><a href="#">TRAINS</a><a href="#">BUS</a>
                </span>
            </h5>
            </div>
            <div class="collapse" [collapse]="isCollapsedDestinazione1" [isAnimated]="true" data-parent="#popularRoutes">
              <div class="card-body">
                <ul class="routes-list">
                  <li><i class="fa fa-bus"></i></li>
                  <li><a href="#">Bhopal To Indore <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Bangalore to Chennai <span class="ml-auto">$ 1,999+</span></a></li>
                  <li><a href="#">Srinagar - New Delhi <span class="ml-auto">$ 2,100+</span></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="two">
              <h5 class="collapsed mb-0" (click)="isCollapsedDestinazione2 = !isCollapsedDestinazione2" [attr.aria-expanded]="!isCollapsedDestinazione2" aria-controls="collapseDestinazione2"> Escursioni e Degustazioni
                <span class="nav">
                  <a href="#">HOTELS</a><a href="#">FLIGHTS</a><a href="#">TRAINS</a><a href="#">BUS</a>
                </span>
            </h5>
            </div>
            <div class="collapse" [collapse]="isCollapsedDestinazione2" [isAnimated]="true" data-parent="#popularRoutes">
              <div class="card-body">
                <ul class="routes-list">
                  <li><i class="fa fa-bus"></i></li>
                  <li><a href="#">Bhopal To Indore <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Bangalore to Chennai <span class="ml-auto">$ 1,999+</span></a></li>
                  <li><a href="#">Srinagar - New Delhi <span class="ml-auto">$ 2,100+</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="accordion accordion-alternate popularRoutes mx-lg-2" id="popularRoutes2">
          <div class="card">
            <div class="card-header" id="six">
              <h5 class="collapsed mb-0" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"> Idee Weekend </h5>
            </div>
            <div id="collapseSix" class="collapse" aria-labelledby="six" data-parent="#popularRoutes2">
              <div class="card-body">
                <ul class="routes-list">
                  <li><i class="fa fa-bed"></i></li>
                  <li><a href="#">The Orchid Hotel <span class="ml-auto">$ 210+</span></a></li>
                  <li><a href="#">Whistling Meadows Resort <span class="ml-auto">$ 675+</span></a></li>
                  <li><a href="#">Radisson Blu Hotel <span class="ml-auto">$ 280+</span></a></li>
                  <li><a href="#">The Lotus Hotel <span class="ml-auto">$ 412+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-plane"></i></li>
                  <li><a href="#">Jaipur - Chicago <span class="ml-auto">$ 1,015+</span></a></li>
                  <li><a href="#">Varanasi - Chicago <span class="ml-auto">$ 3,152+</span></a></li>
                  <li><a href="#">Amritsar - Chicago <span class="ml-auto">$ 4,137+</span></a></li>
                  <li><a href="#">Ahmedabad - Chicago <span class="ml-auto">$ 925+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-train"></i></li>
                  <li><a href="#">Surat - Chicago <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Kolkata - Chicago <span class="ml-auto">$ 1,999+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-bus"></i></li>
                  <li><a href="#">Bhopal To Chicago <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Bangalore to Chicago <span class="ml-auto">$ 1,999+</span></a></li>
                  <li><a href="#">Srinagar - Chicago <span class="ml-auto">$ 2,100+</span></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="seven">
              <h5 class="collapsed mb-0" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseTwo"> Viaggi di Gruppo </h5>
            </div>
            <div id="collapseSeven" class="collapse" aria-labelledby="seven" data-parent="#popularRoutes2">
              <div class="card-body">
                <ul class="routes-list">
                  <li><i class="fa fa-bed"></i></li>
                  <li><a href="#">The Orchid Hotel <span class="ml-auto">$ 210+</span></a></li>
                  <li><a href="#">Whistling Meadows Resort <span class="ml-auto">$ 675+</span></a></li>
                  <li><a href="#">Radisson Blu Hotel <span class="ml-auto">$ 280+</span></a></li>
                  <li><a href="#">The Lotus Hotel <span class="ml-auto">$ 412+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-plane"></i></li>
                  <li><a href="#">Jaipur - New York <span class="ml-auto">$ 1,015+</span></a></li>
                  <li><a href="#">Varanasi - New York <span class="ml-auto">$ 3,152+</span></a></li>
                  <li><a href="#">Amritsar - New York <span class="ml-auto">$ 4,137+</span></a></li>
                  <li><a href="#">Ahmedabad - New York <span class="ml-auto">$ 925+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-train"></i></li>
                  <li><a href="#">Surat - New York <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Kolkata - New York <span class="ml-auto">$ 1,999+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-bus"></i></li>
                  <li><a href="#">Surat - New York <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Kolkata - New York <span class="ml-auto">$ 1,999+</span></a></li>
                  <li><a href="#">Srinagar - New York <span class="ml-auto">$ 2,100+</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="accordion accordion-alternate popularRoutes mx-lg-2" id="popularRoutes3">
          <div class="card">
            <div class="card-header" id="eleven">
              <h5 class="collapsed mb-0" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven"> Shopping Tour </h5>
            </div>
            <div id="collapseEleven" class="collapse" aria-labelledby="eleven" data-parent="#popularRoutes3">
              <div class="card-body">
                <ul class="routes-list">
                  <li><i class="fa fa-bed"></i></li>
                  <li><a href="#">The Orchid Hotel <span class="ml-auto">$ 210+</span></a></li>
                  <li><a href="#">Whistling Meadows Resort <span class="ml-auto">$ 675+</span></a></li>
                  <li><a href="#">Radisson Blu Hotel <span class="ml-auto">$ 280+</span></a></li>
                  <li><a href="#">The Lotus Hotel <span class="ml-auto">$ 412+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-plane"></i></li>
                  <li><a href="#">Jaipur - Bangkok <span class="ml-auto">$ 1,015+</span></a></li>
                  <li><a href="#">Varanasi - Bangkok <span class="ml-auto">$ 3,152+</span></a></li>
                  <li><a href="#">Amritsar - Bangkok <span class="ml-auto">$ 4,137+</span></a></li>
                  <li><a href="#">Ahmedabad - Bangkok <span class="ml-auto">$ 925+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-train"></i></li>
                  <li><a href="#">Surat - Bangkok <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Kolkata - Bangkok <span class="ml-auto">$ 1,999+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-bus"></i></li>
                  <li><a href="#">Surat - Bangkok <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Kolkata - Bangkok <span class="ml-auto">$ 1,999+</span></a></li>
                  <li><a href="#">Srinagar - Bangkok <span class="ml-auto">$ 2,100+</span></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="twelve">
              <h5 class="collapsed mb-0" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve"> Gite Scolastiche  </h5>
            </div>
            <div id="collapseTwelve" class="collapse" aria-labelledby="twelve" data-parent="#popularRoutes3">
              <div class="card-body">
                <ul class="routes-list">
                  <li><i class="fa fa-bed"></i></li>
                  <li><a href="#">The Orchid Hotel <span class="ml-auto">$ 210+</span></a></li>
                  <li><a href="#">Whistling Meadows Resort <span class="ml-auto">$ 675+</span></a></li>
                  <li><a href="#">Radisson Blu Hotel <span class="ml-auto">$ 280+</span></a></li>
                  <li><a href="#">The Lotus Hotel <span class="ml-auto">$ 412+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-plane"></i></li>
                  <li><a href="#">Jaipur - Singapore <span class="ml-auto">$ 1,015+</span></a></li>
                  <li><a href="#">Varanasi - Singapore <span class="ml-auto">$ 3,152+</span></a></li>
                  <li><a href="#">Amritsar - Singapore <span class="ml-auto">$ 4,137+</span></a></li>
                  <li><a href="#">Ahmedabad - Singapore <span class="ml-auto">$ 925+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-train"></i></li>
                  <li><a href="#">Surat - Singapore <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Kolkata - Singapore <span class="ml-auto">$ 1,999+</span></a></li>
                </ul>
                <ul class="routes-list">
                  <li><i class="fa fa-bus"></i></li>
                  <li><a href="#">Bhopal To Singapore <span class="ml-auto">$ 1,209+</span></a></li>
                  <li><a href="#">Bangalore to Singapore <span class="ml-auto">$ 1,999+</span></a></li>
                  <li><a href="#">Srinagar - Singapore <span class="ml-auto">$ 2,100+</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <p class="text-center mb-0"><a href="#" class="btn btn-link">Vedi Tutte <i class="fa fa-arrow-right ml-1"></i></a></p>
      </div>
    </div>
-->
    <!-- Popular Routes end -->

  </div>
</div>



<!-- Mobile App
============================================= -->
<section class="section pb-0">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 text-center"> <img class="img-fluid" alt="" src="assets/images/driver.png"> </div>
      <div class="col-lg-6 text-center text-lg-left" >
        <h2 class="text-8 font-weight-600 my-4">Richiedi un preventivo gratuito<br class="d-none d-lg-inline-block">
          </h2>
        <p class="lead text-dark">(+039) 0522 55 63 33</p>
        <p class="lead text-dark">(+039) 0984 98 08 07</p>
      </div>
    </div>
  </div>
</section>
<!-- Mobile App end -->

<!-- Refer & Earn
============================================= -->
<section class="section bg-secondary text-light shadow-md">
  <div class="container">
    <h2 class="text-9 text-light font-weight-600 text-center">Sedi Operative</h2>
    <div class="row">
      <div class="col-md-4">
        <div class="featured-box style-3">
          <div class="featured-box-icon bg-primary text-light rounded-circle"> <i class="fa fa-home"></i> </div>
          <h3 class="text-light">Reggio Emilia</h3>
          <p class="text-3 opacity-8">noleggi.re@lavalleviaggi.it</p>
        </div>
      </div>
      <div class="col-md-4 mt-4 mt-md-0">
        <div class="featured-box style-3">
          <div class="featured-box-icon bg-primary text-light rounded-circle"> <i class="fa fa-home"></i> </div>
          <h3 class="text-light">Cosenza</h3>
          <p class="text-3 opacity-8">noleggi.cs@lavalleviaggi.it</p>
        </div>
      </div>
      <div class="col-md-4 mt-4 mt-md-0">
        <div class="featured-box style-3">
          <div class="featured-box-icon bg-primary text-light rounded-circle"> <i class="fa fa-home"></i> </div>
          <h3 class="text-light">Milano</h3>
          <p class="text-3 opacity-8">noleggi.mi@lavalleviaggi.it</p>
        </div>
      </div>
    </div>
    <!--
    <div class="text-center pt-4"> <a href="#" class="btn btn-outline-light">Inizia a guadagnare</a> </div>
    -->
  </div>
</section>
<!-- Refer & Earn end -->
<!-- Mobile App
============================================= -->
<!--
<section class="section pb-0">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 text-center text-lg-left">
        <h2 class="text-6 font-weight-400 my-4"><br class="d-none d-lg-inline-block">
          </h2>
          <a class="nav-link" title="Carta della Mobilità" href="assets/file/MOBILITA2023.pdf" target="_blank">Carta della Mobilità</a>          
      </div>
      <div class="col-lg-4 text-center"><a class="nav-link" title="Carta della Mobilità" href="assets/file/MOBILITA2023.pdf" target="_blank"><img class="img-fluid" alt="" src="assets/images/mobilita.jpg"></a></div>
    </div>
  </div>
</section>
-->
<!-- Mobile App end -->
</div>
