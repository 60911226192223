import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  headers = new HttpHeaders(
    {'Authorization': 'Basic  '+btoa(environment.apiUrlUsername+":"+environment.apiUrlPwd)}
  )

  constructor(private router:Router ,private httpClient:HttpClient) {

  }

  inviaRichiestaContatto(cliente:string,telefono:string,email:string,note:string){
    console.log('qui');
    let body = 'cliente='+cliente+'&telefono='+telefono+'&email='+email+'&note='+note;
    return this.httpClient.post<any>(environment.apiUrl+'inviaRichiestaContatto',body, {headers:this.headers});
  }
  registrazioneUtente(nome:string,cognome:string,email:string,password:string,dataNascita:string){
    let body = 'nome='+nome+'&cognome='+cognome+'&email='+email+'&password='+password+'&dataNascita='+dataNascita;
    return this.httpClient.post<any>(environment.apiUrl+'registrazioneUtente',body, {headers:this.headers});
  }
  confermaRegistrazione(email:string,id:number,verificationCode:string){
    let body = 'email='+email+'&id='+id+'&verificationCode='+verificationCode;
    return this.httpClient.post<any>(environment.apiUrl+'confermaRegistrazione',body, {headers:this.headers});
  }
  resetPassword(email:string){
    let body = 'email='+email;
    return this.httpClient.post<any>(environment.apiUrl+'resetPassword',body, {headers:this.headers});
  }

}
