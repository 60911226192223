<!-- Login Modal
=========================== -->
<div class="modal-body py-4 px-0">
  <button type="button" class="close close-outside" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"> <span aria-hidden="true">&times;</span> </button>
  <!-- Login Form
  ====================== -->
  <div class="row">
    <div class="col-11 col-md-10 mx-auto">
      <div class="nav-justified">
        <tabset>
          <tab heading="Login" customClass="style-5">
            <p class="text-4 font-weight-300 text-muted text-center mt-4 mb-4">{{'login_titolologin' | translate}}</p>
            <form id="loginForm" (ngSubmit)="formlogin.valid && login()" #formlogin=ngForm>
              <div class="form-group">
                <input type="email" [(ngModel)]="email" class="form-control" id="emailAddress" name="emailAddress" required placeholder="{{'login_email' | translate}}">
              </div>
              <div class="form-group">
                <input type="password" [(ngModel)]="password" class="form-control" id="loginPassword" name="loginPassword" required placeholder="{{'login_password' | translate}}">
              </div>
              <div class="form-group text-center bg-primary p-1 line-height-1" *ngIf="erroreAutenticazione" >
                <span class="text-1 text-light">{{'login_autenticazionefallita' | translate}}</span>
              </div>
              <div class="row my-4">
                <div class="col">
                  <!--<div class="form-check text-2 custom-control custom-checkbox">
                    <input id="remember-me" name="remember" class="custom-control-input" type="checkbox">
                    <label class="custom-control-label" for="remember-me">{{'login_ricordami' | translate}}</label>
                  </div>-->
                </div>
                <div class="col text-2 text-right"><a class="cursor-pointer btn-link" (click)="goToPasswordDimenticata()" >{{'login_passworddimenticata' | translate}} ?</a></div>
              </div>
              <button class="btn btn-primary btn-block my-4" type="submit" [disabled]="formlogin.invalid">{{'login_login' | translate}}</button>
            </form>

          </tab>
          <tab heading="Registrati" customClass="text-5 line-height-3">
            <p class="text-4 font-weight-300 text-muted text-center mt-4 mb-4">{{'login_titoloregistrazione' | translate}}</p>
            <form id="signupForm" (ngSubmit)="formSignup.valid && signup()" #formSignup=ngForm>
              <div class="form-group">
                <input type="text" class="form-control border-2" id="nome" name="nome" [(ngModel)]="nome" required placeholder="{{'login_nome' | translate}}">
              </div>
              <div class="form-group">
                <input type="text" class="form-control border-2" id="cognome" name="cognome" [(ngModel)]="cognome" required placeholder="{{'login_cognome' | translate}}">
              </div>
              <div class="form-group">
                <input type="email" class="form-control border-2" id="emailAddressSignup" name="emailSignup" [(ngModel)]="emailSignup" required placeholder="{{'login_email' | translate}}">
              </div>
              <div class="form-group">
                <input type="password" class="form-control border-2" id="passwordSignup" name="passwordSignup" [(ngModel)]="passwordSignup" required placeholder="{{'login_password' | translate}}">
              </div>
              <div class="form-group text-center bg-primary p-1 line-height-1" *ngIf="erroreRegistrazione" >
                <span class="text-1 text-light">{{'login_registrazionefallita' | translate}}</span>
              </div>
              <div class="form-group text-center bg-primary p-1 line-height-1" *ngIf="erroreEmailPresente" >
                <span class="text-1 text-light">{{'login_registrazioneemailnondisponibile' | translate}}</span>
              </div>
              <div class="form-group text-center bg-success p-1 line-height-1" *ngIf="messaggioRegistrazioneOk" >
                <span class="text-1 text-light">{{'login_registrazioneok' | translate}}</span>
              </div>
              <div class="form-group my-4">
                <div class="form-check text-2 custom-control custom-checkbox">
                  <input id="agree" name="agree" class="custom-control-input" type="checkbox" required [(ngModel)]="agree">
                  <label class="custom-control-label" for="agree">{{'login_accettoi' | translate}} <a href="#">{{'login_termini'|translate}}</a> {{'login_e' | translate}} <a href="#">{{'login_privacypolicy' | translate}}</a>.</label>
                </div>
              </div>
              <button class="btn btn-primary btn-block my-4" [disabled]="!formSignup.valid" >{{'login_registrati'|translate}}</button>
            </form>
          </tab>
        </tabset>
      </div>

    </div>
  </div>
  <!-- Login Form End -->
</div>
