<header id="header">
  <div class="container">
    <div class="header-row">
      <div class="header-column justify-content-start">

        <!-- Logo
        ============================================= -->
        <div class="logo"> <a [routerLink]="['/home']" class="d-flex" title="La Valle Interlines"><img src="assets/images/logo.png" alt="Quickai" /></a> </div>
        <!-- Logo end -->

      </div>
      <div class="header-column justify-content-end">
        <nav class="primary-menu navbar navbar-expand-lg">
          <div id="header-nav" class="collapse navbar-collapse" [ngClass]="isCollapsedMobileMenu ? '' : 'show'">
            <ul class="navbar-nav">
              <!-- per gestire il menu con l'hover del mouse sulle voci di menu
                 <li class="dropdown active" dropdown #dp="bs-dropdown" triggers="hover" (onShown)="onMenuItemShown()" (onHidden)="onMenuItemHidden()" (isOpenChange)="isMenuItemOpenChange()" >
                    <a id="menu-item-home" class="dropdown-toggle" href="#" dropdownToggle (mouseout)="dp.hide()" aria-controls="dropdown-home-menu">Home
                -->
              <li>
                <a class="pr-0 font-weight-500" title="Home" [routerLink]="['/home']">Home</a>
			        </li>
              <li>
                <a class="pr-0 font-weight-500" title="Azienda" [routerLink]="['/azienda']">{{'azienda_headertitle' | translate}}</a>
              </li>
              <li>
                <a class="pr-0 font-weight-500" title="Novita ed Avvisi" [routerLink]="['/novita']">{{'novita_headertitle' | translate}}</a>
              </li>
              <li>
                <a class="pr-0 font-weight-500" title="Contatti" [routerLink]="['/contact']">{{'contatti_headertitle' | translate}}</a>
              </li>
              <li>
                <a class="pr-0 font-weight-500" title="Support" [routerLink]="['/support']">{{'support_headertitle' | translate}}</a>
              </li>
              <!--
              <li class="dropdown cursor-pointer" dropdown #dp="bs-dropdown" >
                <a id="menu-item-home" class="dropdown-toggle" dropdownToggle aria-controls="dropdown-home-menu">Altre Pagine
                <i class="arrow"></i>
                </a>
                <ul id="dropdown-home-menu" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="menu-item-home">
                  <li><a class="dropdown-item" href="index-11.html">Index 11 - (Mobile Top-Up)</a></li>
                </ul>
              </li>
               -->
            </ul>
          </div>
        </nav>

    <!-- Collapse Button
    =============================== -->
    <button id="navbar-toggler" class="navbar-toggler" type="button" dropdown dropdownToggle (click)="isCollapsedMobileMenu = !isCollapsedMobileMenu" > <span></span> <span></span> <span></span> </button>

    <!-- Login Signup
    =============================== -->
    <!--
    <nav class="login-signup navbar navbar-expand separator ml-sm-2 pl-sm-2">
    <ul class="navbar-nav">
      <li class="profile" *ngIf="!userInfo.autenticato" >
        <a class="pr-0 cursor-pointer" *ngIf="!userInfo.autenticato" data-toggle="modal" data-target="#login-modal" title="Login / Sign up" (click)="openLoginComponent()"><span class="d-none d-sm-inline-block">Login</span> <span class="user-icon ml-sm-2"><i class="fa fa-user"></i></span></a>
      </li>
      <li class="dropdown profile" dropdown #dpProfile="bs-dropdown" *ngIf="userInfo.autenticato">
        <a id="menu-item-profile" *ngIf="userInfo.autenticato" class="dropdown-toggle pr-0 cursor-pointer" dropdownToggle aria-controls="dropdown-profile">
          <span class="d-none d-sm-inline-block">{{'navbar_mioprofilo' | translate}}</span> <span class="user-icon ml-sm-2"><i class="fa fa-user"></i></span>
        </a>
        <ul id="dropdown-profile" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="menu-item-profile">
          <li class="text-center text-3 py-2">{{'navbar_ciao' | translate}}, {{userInfo.nome}}</li>
					<li class="dropdown-divider mx-n3"></li>
          <li><a class="dropdown-item" href="index-11.html">{{'navbar_informazionipersonali' | translate}}</a></li>
          <li><a class="dropdown-item" href="index-11.html">{{'navbar_bigliettiacquistati' | translate}}</a></li>
          <li><a class="cursor-pointer dropdown-item" (click)="logout();" >{{'navbar_logout' | translate}}</a></li>
        </ul>
      </li>
    </ul>
    </nav>-->
      </div>
    </div>
  </div>
</header>
