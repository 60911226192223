import { Component, OnInit } from '@angular/core';
import { NewsTutto } from '../models/newstutto';
import { News } from '../models/news';
import { NewsCategorie } from '../models/newscategorie';
import { NewsRecenti } from '../models/newsrecenti';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PagecontentService } from '../services/pagecontent.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-novita',
  templateUrl: './novita.component.html',
  styleUrls: ['./novita.component.css']
})
export class NovitaComponent implements OnInit {
  infonews:NewsTutto = new NewsTutto();
  listaNews:News[];
  listaNewsCategorie:NewsCategorie[];
  listaNewsRecenti:NewsRecenti[];
  listImage65x65: any[];
  listImage540x390: any[];
  selectedNews:News = new News();
  filtroDescrizione: string = "";
  selectedCategoria:number =0;

  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private pageService: PagecontentService, public authService: AuthService, private translateService:TranslateService,private sanitizer: DomSanitizer) {
    if (typeof history.state.selectedCategoria === "undefined"){
      this.selectedCategoria = 0;
    }else{
      this.selectedCategoria = history.state.selectedCategoria;
    }

    if (typeof history.state.filtroDescrizione === "undefined"){
      this.filtroDescrizione = '';
    }else{
      this.filtroDescrizione = history.state.filtroDescrizione;
    }

   }

  ngOnInit(): void {
    this.refreshNovita(1);
    this.selectedNews=new News();

  }

  onRefresh(){
    this.selectedCategoria = 0;
    this.refreshNovita(0);
  }


  onRefreshCategoria(selected: number){
    this.selectedCategoria = selected;
    this.filtroDescrizione = "";
    this.refreshNovita(0);
  }

  //per NEWS
  refreshNovita(leggitutto: number){
    this.listImage540x390 = [];
    this.listaNews = [];
    this.spinner.show();    
    this.pageService.getNews(leggitutto,this.filtroDescrizione,this.selectedCategoria,0).subscribe(
      response => {
        this.spinner.hide();
        this.infonews = response;
        this.listaNews = this.infonews.listnews;
        if(leggitutto==1){
          this.listaNewsCategorie =this.infonews.listnewscategorie;
          this.listaNewsRecenti = this.infonews.listnewsrecenti;
          this.listImage65x65 = [];
          this.getUrlPiccole();
          }
        this.getUrlMedie();
       },
      error =>{
        this.spinner.hide();
        console.log(error);
      }

    )
    }


    getUrlMedie() {
      let index :number;
      let newurl :string;
      for(let i=0;i<this.listaNews.length;i++){
        index = this.listaNews[i].urlfoto.lastIndexOf(".");
        newurl =  this.listaNews[i].urlfoto.substring(0,index);
        newurl+= "M." + this.listaNews[i].fotoext;
        this.listImage540x390[i] = newurl;
//        this.listaNews[i].descrizione = this.sanitizer.bypassSecurityTrustHtml(this.listaNews[i].descrizione);
        this.listaNews[i].shortdescrizione = this.sanitizer.bypassSecurityTrustHtml(this.listaNews[i].shortdescrizione);

        }
    }
    getUrlPiccole() {
      let index :number;
      let newurl :string;
      for(let i=0;i<this.listaNewsRecenti.length;i++){
        index = this.listaNewsRecenti[i].urlfoto.lastIndexOf(".");
        newurl =  this.listaNewsRecenti[i].urlfoto.substring(0,index);
        newurl+= "P." + this.listaNewsRecenti[i].fotoext;
        this.listImage65x65[i] = newurl;
        }
    }

    onSelect(selected: News): void {
      this.selectedNews = selected;
      this.router.navigate(['novita-single'],{ queryParams: { idNews: this.selectedNews.codice}});

    }

  }



