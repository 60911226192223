<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1>{{'support_headertitle' | translate}}</h1>
        </div>
        <div class="col-md-4">
          <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
            <li><a href="index.html">Home</a></li>
            <li class="active">{{'support_headertitle' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Page Header end -->
    <!-- Content
  ============================================= -->
  <div id="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="bg-white shadow-md rounded p-4">
            <h2 class="text-6 mb-4">{{'support_boxtitle' | translate}}</h2>
			<hr class="mx-n4 mb-4">
            <p class="text-3">{{'support_boxinfo' | translate}}</p>
            <form id="recharge-bill" (ngSubmit)="formSupport.valid && inviaRichiesta()" #formSupport=ngForm>
              <div class="form-group">
			  <label for="yourName">{{'support_boxnome' | translate}}</label>
                <input type="text" class="form-control" id="yourName" required placeholder="{{'support_boxnomeinput' | translate}}" [(ngModel)]="nomeCliente" name="name">
              </div>
              <div class="form-group">
			  <label for="yourEmail">{{'support_boxemail' | translate}}</label>
                <input type="email" class="form-control" id="yourEmail" required placeholder="{{'support_boxemailinput' | translate}}" [(ngModel)]="emailCliente" name="email">
              </div>
              <div class="form-group">
			  <label for="mobileNumber">{{'support_boxtelefono' | translate}}</label>
                <input type="text" class="form-control" data-bv-field="number" id="mobileNumber" required placeholder="{{'support_boxtelefonoinput' | translate}}" [(ngModel)]="telefonoCliente" name="telefono">
              </div>
              <div class="form-group">
			  <label for="yourProblem">{{'support_boxrichiesta' | translate}}</label>
                <textarea class="form-control" rows="5" id="yourProblem" required placeholder="{{'support_boxrichiestainput' | translate}}" [(ngModel)]="noteCliente" name="note"></textarea>
              </div>
              <button class="btn btn-primary btn-block" [disabled]="!formSupport.valid" >{{'support_boxinvia' | translate}}</button>
              <div class="text-center bg-success p-2 rounded mt-2" *ngIf="esitoRichiesta==1" ><span class="text-light ">{{'support_richiestaok' | translate}}</span></div>
              <div class="text-center bg-primary-color-disabled p-2 rounded mt-2" *ngIf="esitoRichiesta==2" ><span class="text-light "><strong>{{'support_spiacenti' | translate}}, </strong> {{'support_richiestako' | translate}}</span></div>
            </form>
          </div>
        </div>
        <!--
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="bg-white shadow-md rounded p-4">

            <h2 class="text-6 mb-4">FAQ</h2>
			<hr class="mx-n4 mb-4">
            <div class="accordion accordion-alternate mt-n3" id="accordion">
              <div class="card">
                <div class="card-header" id="heading1">
                  <h5 class="mb-0"> <a href="#" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">How can I make a account?</a> </h5>
                </div>
                <div id="collapse1" class="collapse show" aria-labelledby="heading1" data-parent="#accordion">
                  <div class="card-body"> Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Mutat tacimates id sit. Ridens mediocritatem ius an, eu nec magna imperdiet. </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading2">
                  <h5 class="mb-0"> <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">Is there any registration fee?</a> </h5>
                </div>
                <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion">
                  <div class="card-body"> Iisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Mutat tacimates id sit. Ridens mediocritatem ius an, eu nec magna imperdiet. </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading3">
                  <h5 class="mb-0"> <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">Is my account information safe?</a> </h5>
                </div>
                <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordion">
                  <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading4">
                  <h5 class="mb-0"> <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">How does it work?</a> </h5>
                </div>
                <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
                  <div class="card-body"> Iisque Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading5">
                  <h5 class="mb-0"> <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">I did not receive the cashback</a> </h5>
                </div>
                <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                  <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading6">
                  <h5 class="mb-0"> <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">Forgot my password! What next?</a> </h5>
                </div>
                <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                  <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading7">
                  <h5 class="mb-0"> <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">Closing Your Account</a> </h5>
                </div>
                <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion">
                  <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                </div>
              </div>
            </div>
            <a href="faq.html" class="btn btn-link btn-block btn-sm"><u>Click Here for more FAQ</u></a> </div>
        </div>

      </div>
    </div> -->
  </div><!-- Content end -->
