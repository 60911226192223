<!-- Page Header============================================= -->
<section class="page-header page-header-text-light bg-primary">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1>{{'contatti_headertitle' | translate}}</h1>
      </div>
      <div class="col-md-4">
        <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
          <li><a href="index.html">Home</a></li>
          <li class="active">{{'contatti_headertitle' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- Page Header end -->
  <!-- Content
============================================= -->
<div id="content">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="bg-white shadow-md rounded h-100 p-3">
          <iframe class="h-100 w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5672.681858276923!2d10.655696!3d44.69223800000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbd2049dd01837fb7!2sL.v.l.%20Interlines%20S.r.l.!5e0!3m2!1sit!2sin!4v1618482832665!5m2!1sit!2sin" allowfullscreen loading="lazy"></iframe>
      </div>
      </div>
      <div class="col-md-6 mt-4 mt-md-0">
        <div class="bg-white shadow-md rounded p-4">
          <h2 class="text-6 mb-4">{{'contatti_inostricontatti' | translate}}</h2>
    <hr class="mx-n4 mb-4">
          <div class="featured-box style-1">
            <div class="featured-box-icon text-primary"> <i class="fa fa-map-marker"></i></div>
            <h3>LVL Interlines s.r.l.</h3>
            <h2 class="text-2">Sede Legale ed Operativa</h2>
            <p>Via Guicciardi, 9<br>
              42122 Reggio Emilia<br>
              P.IVA 02493480780</p>
              <h2 class="text-2">Sede Operativa di Cosenza</h2>
              <p>Zona Industriale<br>
                87050 Mangone (Cosenza)
                </p>
          </div>
          <div class="featured-box style-1">
            <div class="featured-box-icon text-primary"> <i class="fa fa-phone"></i> </div>
            <h3>{{'contatti_inostricontatti' | translate}}</h3>
            <p>(+039) 0522 55 63 33<br>(+039) 0984 98 08 07</p>
          </div>
          <div class="featured-box style-1">
            <div class="featured-box-icon text-primary"> <i class="fa fa-envelope"></i> </div>
            <h3>{{'contatti_email' | translate}}</h3>
            <p>info@lavalleviaggi.it</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div><!-- Content end -->
