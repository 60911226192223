import { Passeggero } from './passeggero';
import { Viaggio } from './viaggio';

export class Biglietto{
  viaggioAndata:Viaggio;
  viaggioRitorno:Viaggio;
  passeggeriAdulti:Passeggero[] = [];
  passeggeriBambini:Passeggero[] = [];
  emailContatto: string = '';
  telefonoContatto: string = '';
  codicePromozionale: string = '';
}
