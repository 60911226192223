import { Noleggio } from './../models/noleggio';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoleggioService {

  headers = new HttpHeaders(
    {'Authorization': 'Basic  '+btoa(environment.apiUrlUsername+":"+environment.apiUrlPwd)}
  )

  constructor(private router:Router ,private httpClient:HttpClient) {

  }
  getLocalCart(fieldid:string){
    return sessionStorage.getItem(fieldid);
  }
  addLocalCart(fieldid:string,value:string){
    sessionStorage.setItem(fieldid,value);
  }
  svuotaLocalCart(){
    sessionStorage.removeItem("cart_noleggio");
  }
  getNoleggio(tipoNoleggio:number,indirizzoPartenza:string,indirizzoArrivo:string,dataPartenza:string, oraPartenza:string,numeroPasseggeri:number,dataRitorno:string, oraRitorno:string ){
    let body = 'keyPortale='+environment.keyPortal+'&tipoNoleggio='+tipoNoleggio+'&cittaPartenza='+indirizzoPartenza+'&cittaArrivo='+indirizzoArrivo+'&dataPartenza='+dataPartenza+'&oraPartenza='+oraPartenza+'&numClienti='+numeroPasseggeri+'&dataRitorno='+dataRitorno+'&oraRitorno='+oraRitorno;
    return this.httpClient.post<Noleggio>(environment.apiUrl+'getNoleggio',body, {headers:this.headers});
  }
  inviaRichiesta(numeroPreventivo:string,cliente:string,telefono:string,email:string,note:string){
    let body = 'numeroPreventivo='+numeroPreventivo+'&cliente='+cliente+'&telefono='+telefono+'&email='+email+'&note='+note;
    return this.httpClient.post<any>(environment.apiUrl+'inviaRichiestaNoleggio',body, {headers:this.headers});
  }
}
