import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  nomeCliente:any;
  telefonoCliente:any;
  emailCliente:any;
  noteCliente:any;
  risposta:any;
  esitoRichiesta:number=0;

  constructor(private translateService:TranslateService,private profileService: ProfileService) { }

  ngOnInit(): void {
    this.esitoRichiesta = 0;
  }


  inviaRichiesta(){
    this.esitoRichiesta=0;
    this.profileService.inviaRichiestaContatto(this.nomeCliente,this.telefonoCliente,this.emailCliente,this.noteCliente).subscribe(
      resp => {
        if(resp.response && resp.response==='ok')
          this.esitoRichiesta=1;
        else
          this.esitoRichiesta=2;
       },
      error =>{
        this.esitoRichiesta=2;
      }

    )

    ;
  }



}
