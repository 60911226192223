import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Biglietto } from '../models/biglietto';
import { AuthService } from '../services/auth.service';
import { CorseService } from '../services/corse.service';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.css']
})
export class PagamentoComponent implements OnInit {
  biglietto:Biglietto;

  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private corseService: CorseService,public authService: AuthService, private translateService:TranslateService) {
    if(this.corseService.getLocalCart("cart")){
      this.biglietto=JSON.parse(this.corseService.getLocalCart("cart"));
      let cartId=this.corseService.getLocalCart("cart_id");
      this.spinner.show();
      this.corseService.controlloValiditaCarrello(this.corseService.getLocalCart("cart"),cartId,false).subscribe(
        valid => {
          this.spinner.hide();
          if(!valid) this.router.navigate(['/booking-expired']);
        },
        (err) =>{
          this.spinner.hide();
          this.router.navigate(['/service-unavailable']);
        }
      );
    }
  }

  ngOnInit(): void {
    if(!this.biglietto)
      this.router.navigate(['/booking-expired']);
  }

}
