<footer id="footer">
  <!--
  <section class="section bg-white shadow-md pt-4 pb-3">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <div class="featured-box text-center">
            <div class="featured-box-icon"> <i class="fa fa-lock"></i> </div>
            <h4>100% Pagamenti Sicuri</h4>
            <p>Spostare i dettagli della tua carta in un luogo molto più sicuro.</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="featured-box text-center">
            <div class="featured-box-icon"> <i class="fa fa-thumbs-up"></i> </div>
            <h4>Pagamenti Protetti</h4>
            <p>100% Protezione Pagamento. Regole di rimborso facili.</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="featured-box text-center">
            <div class="featured-box-icon"> <i class="fa fa-bullhorn"></i> </div>
            <h4>Condividi e Guadagna</h4>
            <p>Invita un amico a registrarsi e guadagni anche tu</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="featured-box text-center">
            <div class="featured-box-icon"> <i class="fa fa-life-ring"></i> </div>
            <h4>Assistenza</h4>
            <p>Sei in difficolta? Hai delle richieste ? <a href="#">Clicca qui</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  -->
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-4 mb-3 mb-md-0">
        <p>Pagamenti</p>
        <ul class="payments-types">
          <li><a href="#" target="_blank"> <img data-toggle="tooltip" src="assets/images/payment/visa.png" alt="visa" title="Visa"></a></li>
          <li><a href="#" target="_blank"> <img data-toggle="tooltip" src="assets/images/payment/discover.png" alt="discover" title="Discover"></a></li>
          <li><a href="#" target="_blank"> <img data-toggle="tooltip" src="assets/images/payment/paypal.png" alt="paypal" title="PayPal"></a></li>
          <li><a href="#" target="_blank"> <img data-toggle="tooltip" src="assets/images/payment/american.png" alt="american express" title="American Express"></a></li>
          <li><a href="#" target="_blank"> <img data-toggle="tooltip" src="assets/images/payment/mastercard.png" alt="discover" title="Discover"></a></li>
        </ul>
      </div>
      <div class="col-md-3 mb-3 mb-md-0">
        <a class="nav-link" title="Carta della Mobilità" href="assets/file/MOBILITA2023.pdf" target="_blank"><img class="img-fluid" alt="" src="assets/images/mob2023.png"></a>
      </div>
      <div class="col-md-3 mb-3 mb-md-0">
        <a class="nav-link" title="Certificato DNV ISO 9001" href="assets/file/ISO-9001.pdf" target="_blank"><img class="img-fluid" alt="" src="assets/images/iso.png"></a>
      </div>
      <div class="col-md-2 d-flex align-items-md-end flex-column">
        <p>{{'footer_seguicisu' | translate}}</p>
        <ul class="social-icons">
          <li class="social-icons-facebook"><a data-toggle="tooltip" href="https://www.facebook.com/lavalleviaggi" target="_blank" title="Facebook"><i class="fa fa-facebook-f"></i></a></li>
          <li class="social-icons-instagram"><a data-toggle="tooltip" href="https://instagram.com/lavalleviaggi" target="_blank" title="Instagram"><i class="fa fa-instagram"></i></a></li>
          <!--
          <li class="social-icons-twitter"><a data-toggle="tooltip" href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li>
          <li class="social-icons-google"><a data-toggle="tooltip" href="http://www.google.com/" target="_blank" title="Google"><i class="fa fa-google"></i></a></li>
          <li class="social-icons-linkedin"><a data-toggle="tooltip" href="http://www.linkedin.com/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
          <li class="social-icons-youtube"><a data-toggle="tooltip" href="http://www.youtube.com/" target="_blank" title="Youtube"><i class="fab fa-youtube"></i></a></li>
          <li class="social-icons-instagram"><a data-toggle="tooltip" href="http://www.instagram.com/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
          -->
        </ul>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="footer-copyright">
      <ul class="nav justify-content-center">
        <li class="nav-item"> <a class="nav-link" [routerLink]="['/azienda']">{{'azienda_headertitle' | translate}}</a> </li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['/contact']">{{'contatti_headertitle' | translate}}</a> </li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['/support']">{{'support_headertitle' | translate}}</a> </li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['/privacy']">Privacy Policy</a> </li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['/cookiepage']">Cookie Policy</a> </li>
        <li class="nav-item"> <a class="nav-link" title="Qualità" href="assets/file/QUALITA2022.pdf" target="_blank">Qualità</a></li>
      </ul>
      <p class="copyright-text">Copyright © 2021-2023 <a [routerLink]="['/home']">LVL Interlines s.r.l.</a>. All Rights Reserved.</p>
    </div>
  </div>
</footer>
