import { Azienda } from '../models/azienda';
import { Noleggio } from './../models/noleggio';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { PagecontentService } from '../services/pagecontent.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-azienda',
  templateUrl: './azienda.component.html',
  styleUrls: ['./azienda.component.css']
})
export class AziendaComponent implements OnInit {
  infoazienda:Azienda = new Azienda();
  infonoleggio:Noleggio = new Noleggio();

  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private pageService: PagecontentService,public authService: AuthService, private translateService:TranslateService,private sanitizer: DomSanitizer) {


  }

  ngOnInit(): void {
    this.infoazienda=new Azienda();
  //  this.infonoleggio=new Noleggio();
    this.refreshAzienda();
  }


refreshAzienda(){

  this.spinner.show();
  this.pageService.getAzienda().subscribe(
  response => {
    this.spinner.hide();
    this.infoazienda=response;
      this.infoazienda.descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infoazienda.descrizione);
      this.infoazienda.listbox[0].descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infoazienda.listbox[0].descrizione);
      this.infoazienda.listbox[1].descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infoazienda.listbox[1].descrizione);
      this.infoazienda.listbox[2].descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infoazienda.listbox[2].descrizione);

    },
    error =>{
      console.log(error);
      this.spinner.hide();
    }
  )

  }

}
