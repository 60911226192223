import { UserInfo } from './../models/userInfo';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginStatus = new BehaviorSubject<UserInfo>(this.isAuthenticated());

  constructor(private router:Router,private httpClient:HttpClient) {
    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(
        (e) => {
          this.isAuthenticated();
        }
    );
  }

  public authenticate(username:string,password:string) {
    let headers = new HttpHeaders(
      {'Authorization': 'Basic  '+btoa(environment.apiUrlUsername+":"+environment.apiUrlPwd)}
    )
    let body = 'username='+username+"&password="+password;
    return this.httpClient.post<any>(environment.apiUrl+'login',body, {headers:headers}).pipe(
      map(
        resp => {
          if(resp && resp.token){
            localStorage.setItem('authToken',resp.token);

            let userInfo=new UserInfo();
            userInfo.autenticato=true;
            userInfo.nome=resp.nome;
            userInfo.cognome=resp.cognome;
            userInfo.email=resp.email;
            userInfo.ruolo=resp.ruolo;
            userInfo.telefono=resp.telefono;
            this.loginStatus.next(userInfo);  //AVVISO UN CAMBIAMENTO SU LOGINSTATUS
          }
          return resp;
        }
      )
    );
  }

  logout() {
    let userInfo=new UserInfo();
    this.loginStatus.next(userInfo);
    localStorage.removeItem('authToken');
    this.router.navigate(['/logout']);
  }

  public checkToken(token: string):Observable<any> {
    //console.log('AuthService -> checkToken '+token);
    let headers = new HttpHeaders(
      {'Authorization': 'Bearer  '+token}
    )
    let body="";
    return this.httpClient.post<any>(environment.apiUrl+'checkTokenLogin',body, {headers:headers});
  }

  public isAuthenticated(): UserInfo {
    //console.log('AuthService -> isAuthenticated');
    let userInfo = new UserInfo();
    let token = this.getToken();
    if (token){
      //VERIFICA DEL TOKEN
      this.checkToken(token).subscribe(
        data => {
            console.log("memorizzo in localstorage authToken "+data.token);
            localStorage.setItem('authToken',data.token);
            userInfo.autenticato=true;
            userInfo.cognome=data.cognome;
            userInfo.nome=data.nome;
            userInfo.email=data.email;
            userInfo.ruolo=data.ruolo;
            this.loginStatus.next(userInfo);
            return userInfo;
          },
          error => {
            localStorage.removeItem('authToken');
            this.loginStatus.next(userInfo);
          }
        )
    }
    else{
      return userInfo;
    }
  }

  get isLoggedIn() {
    return this.loginStatus.asObservable();
  }

  public getToken(): string {
    return !localStorage.getItem('authToken')?null:localStorage.getItem('authToken');
  }


}
