  <!-- Content
  ============================================= -->
  <div id="content">
    <section class="d-flex flex-column min-vh-90">
      <div class="container text-center my-auto py-5">
        <div class="row">
          <div class="col-lg-10 mx-auto mt-5">
            <p class="text-7 text-muted font-weight-500 mb-3">{{'prenotazioneexpired_titolo' | translate}}</p>
            <h2 class="text-10 mb-5">{{'prenotazioneexpired_sottotitolo' | translate}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-lg-6 col-xl-5 mx-auto">
            <a [routerLink]="['/home']" class="btn btn-primary shadow-none px-5 m-2">{{'prenotazioneexpired_acquistadinuovo' | translate}}</a>
          </div>
        </div>
      </div>
    </section>
    <!-- 404 end -->

  </div><!-- Content end -->
