import { Noleggio } from './../models/noleggio';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { noop, Observable, Observer, of } from 'rxjs';
import { debounceTime, map, switchMap, tap, filter, distinctUntilChanged } from 'rxjs/operators';
import { HomeDestra } from '../models/homedestra';
import { NgbDateCustomParserFormatterModule } from '../modules/ngb-date-custom-parser-formatter/ngb-date-custom-parser-formatter.module';
import { CorseService } from '../services/corse.service';
import { PagecontentService } from '../services/pagecontent.service';
import { UtilsHelperService } from '../services/utils-helper.service';
import { MapsAPILoader } from '@agm/core';
import { NoleggioService } from '../services/noleggio.service';
import { HomeTutto } from '../models/hometutto';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  })



export class HomeComponent implements OnInit {

  @ViewChild('indirizzoPartenzaElement') public indirizzoPartenzaElement: ElementRef;
  @ViewChild('indirizzoDestinazioneElement') public indirizzoDestinazioneElement: ElementRef;

  public isCollapsedSeatNumber = true;
  public isCollapsedDestinazione1 = true;
  public isCollapsedDestinazione2 = true;
  public isCollapsedDestinazione3 = true;
  public isCollapsedDestinazione4 = true;
  public isCollapsedDestinazione5 = true;
  public isCollapsedDestinazione6 = true;

  dateFormat:string ="dd/MM/yyyy";
  indirizzoPartenza:string;
  cittaPartenza:any;
  cittaArrivo: any;
  indirizzoArrivo:string;
  numeroBambini: number = 0;
  numeroAdulti: number = 1;
  today:Date = new Date();
  dataPartenza:Date = new Date();
  dataRitorno:Date;
  dataPartenzaNoleggio:Date;
  oraPartenzaNoleggio:string;
  dataRitornoNoleggio:Date;
  oraRitornoNoleggio:string;
  tipoNoleggio="4";
  numeroPersoneNoleggio:number;
  placeholderPosti:string = "";

  googlePlacesService;
  indirizziPartenzaResults$: Observable<any[]>;
  indirizziArrivoResults$: Observable<any[]>;

  partenze:any = [
  ];

  destinazioni:any = [
  ];

  //lato destro
  listBoxHome:HomeDestra[] = [];
  infohome:HomeTutto = new HomeTutto();

  //carousel
  itemsPerSlide = 3;
  singleSlideOffset = true;
  noWrap = true;


  slides = [
    {image: 'assets/images/slider/031.jpg'},
    {image: 'assets/images/slider/002.jpg'},
    {image: 'assets/images/slider/008.jpg'},
    {image: 'assets/images/slider/014.jpg'},
    {image: 'assets/images/slider/001.jpg'}
 //   {image: 'assets/images/slider/006.jpg'}
  ];


  carouselTitle: any[] = [];
  carouselDes: any[] = [];




 //end carousel

  constructor(private route: Router, private translateService:TranslateService,private localeService: BsLocaleService,private corseService:CorseService,private noleggioService:NoleggioService,private pageService:PagecontentService,private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,private sanitizer: DomSanitizer,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.carouselTitle[0]= 'COMFORT';
    this.carouselTitle[1]= 'BUS DA 8 a 82 POSTI';
    this.carouselTitle[2]= 'MINI BUS';
    this.carouselTitle[3]= 'DOTAZIONI DI SICUREZZA';
    this.carouselTitle[4]= 'AUTO CON CONDUCENTE';
   // this.carouselTitle[5]= '5AUTO CON';

    this.carouselDes[0] ="Interni luminosi e confortevoli. Sedili Business Class";
    this.carouselDes[1] ="Bus monopiano e doppio piano";
    this.carouselDes[2] ="Da 8 a 30 posti ";
    this.carouselDes[3] ="Bus dotati di dispositivi anticollisione";
    this.carouselDes[4] ="Transfer affidabili e veloci";
  //  this.carouselDes[5] ="assets/images/slider/006.jpg";




    this.dateFormat = UtilsHelperService.getLocaleDateStringToShow(this.translateService.currentLang);
    this.localeService.use('it');

    this.initializeIndirizzoPartenza();
    this.initializeIndirizzoArrivo();
    this.refreshPartenze();
    this.refreshHomeDestra();



  }

  initializeIndirizzoPartenza(){
    this.mapsAPILoader.load().then(
      () => {
        let options={
          componentRestrictions: { country: "it" },
          types:["geocode","establishment"]
        }
        let autocomplete = new google.maps.places.Autocomplete(this.indirizzoPartenzaElement.nativeElement, options);
        autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
         let place: google.maps.places.PlaceResult = autocomplete.getPlace();
         console.log(place);
         if(place.geometry === undefined || place.geometry === null ){
          return;
         }
         this.indirizzoPartenza=place.formatted_address;

        });
        });
      }
    );
  }

/*
PER AUTOCOMPLETE PERSONALIZZATO "FABRIZIO"
  initializeIndirizzoPartenza(){
    this.mapsAPILoader.load().then(
      () => {
      this.googlePlacesService = new google.maps.places.AutocompleteService();

      this.indirizziPartenzaResults$ = new Observable((observer: Observer<string>) => {
        observer.next(this.indirizzoPartenza);
      }).pipe(
        switchMap((query: string) => {
        console.log(query);
         if (query) {
            return this.getPlacePredictions(query).pipe(
              tap(() => noop, err => {
                // in case of http error
                console.log(err && err.message || 'Something goes wrong');
              })
            );
          }
          else
            return of([]);
        })
      );
    });
  }

  getPlacePredictions(term: string): Observable<any[]> {
    return new Observable(observer => {
      // API Call
      this.googlePlacesService.getPlacePredictions({ input: term }, data => {
        this.ngZone.run(() => {
          observer.next(data);
        });
      });
    });
  }
*/

  initializeIndirizzoArrivo(){
    this.mapsAPILoader.load().then(
      () => {
        let options={
          componentRestrictions: { country: "it" },
          types:["geocode","establishment"]
        }
       let autocomplete = new google.maps.places.Autocomplete(this.indirizzoDestinazioneElement.nativeElement, options);
        autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
         let place: google.maps.places.PlaceResult = autocomplete.getPlace();
         if(place.geometry === undefined || place.geometry === null ){
          return;
         }
         this.indirizzoArrivo=place.formatted_address;
        });
        });
      }
    );
  }

  getPlaceholderPosti(){
    return this.numeroAdulti+" "+this.translateService.instant('home_adulti')+" - "+this.numeroBambini+" "+this.translateService.instant('home_bambini');
  }

  refreshPartenze(){
    this.partenze = [];
    this.corseService.getPartenze().subscribe(
      response => {
        this.partenze=response;
      },
      error =>{
        console.log(error);
      }
    )
  }

  refreshDestinazioni(){
    this.destinazioni = [];
    this.corseService.getDestinazioni(this.cittaPartenza.codice).subscribe(
      response => {
        this.destinazioni=response;
      },
      error =>{
        console.log(error);
      }
    )
  }

  cercaSoluzioni(){
    let dataPartenzaText='';
    if(this.dataPartenza!=null) dataPartenzaText=this.dataPartenza.toISOString().slice(0, 10);
    let dataRitornoText='';
    if(this.dataRitorno!=null) dataRitornoText=this.dataRitorno.toISOString().slice(0, 10);
    this.route.navigate(['searchresults'],{ queryParams: { cittaPartenza: this.cittaPartenza.citta, cittaPartenzaRef: this.cittaPartenza.codice, cittaArrivo: this.cittaArrivo.citta, cittaArrivoRef: this.cittaArrivo.codice, dataPartenza:dataPartenzaText, dataRitorno:dataRitornoText, numAdulti:this.numeroAdulti, numBambini:this.numeroBambini, numMaxCambi:0}});
  }

  continuaRichiestaNoleggio(){
    let dataPartenzaText='';
    if(this.dataPartenzaNoleggio!=null) dataPartenzaText=this.dataPartenzaNoleggio.toISOString().slice(0, 10);
    let dataRitornoText='';
    if(this.dataRitornoNoleggio!=null) dataRitornoText=this.dataRitornoNoleggio.toISOString().slice(0, 10);
    this.spinner.show();
    this.noleggioService.getNoleggio(+this.tipoNoleggio,this.indirizzoPartenza,this.indirizzoArrivo,dataPartenzaText,this.oraPartenzaNoleggio,this.numeroPersoneNoleggio,dataRitornoText,this.oraRitornoNoleggio).subscribe(
      resp =>{
        this.spinner.hide();
        let noleggio = resp;
        this.noleggioService.svuotaLocalCart();
        this.noleggioService.addLocalCart("cart_noleggio",JSON.stringify(noleggio));
        console.log(noleggio);
        this.route.navigate(['detail-noleggio']);
      },
      error =>{
        this.spinner.hide();
        console.log(error);
        this.noleggioService.svuotaLocalCart();
        this.route.navigate(['detail-noleggio']);
      }
    );
  }

  refreshHomeDestra(){
    //this.listaDestra=[];
    this.pageService.getHomeDestra().subscribe(
      response => {
        this.infohome =response;
        this.listBoxHome = this.infohome.listbox;
        this.infohome.descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infohome.descrizione);
        this.infohome.listbox[0].descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infohome.listbox[0].descrizione);
        this.infohome.listbox[1].descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infohome.listbox[1].descrizione);
        this.infohome.listbox[2].descrizione = this.sanitizer.bypassSecurityTrustHtml(this.infohome.listbox[2].descrizione);
       },
      error =>{
        console.log(error);
      }
    )

  }
}
