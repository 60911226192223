import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagecontentService {

  headers = new HttpHeaders(
    {'Authorization': 'Basic  '+btoa(environment.apiUrlUsername+":"+environment.apiUrlPwd)}
  )

  constructor(private httpClient:HttpClient) {
  }

  getAzienda(){
    let body = 'keyPortale='+environment.keyPortal;
    return this.httpClient.post<any>(environment.apiUrl+'leggiAzienda',body, {headers:this.headers});

  }

  getNews(leggitutto:number,filtro:string,categoria:number,idnews:number){
    let body = 'keyPortale='+environment.keyPortal+'&leggiTutto='+leggitutto+'&filtroDescrizione='+filtro + '&filtroCategoria='+categoria+ '&idNews='+idnews;
    return this.httpClient.post<any>(environment.apiUrl+'leggiNews',body, {headers:this.headers});

  }

  getHomeDestra(){
    let body = 'keyPortale='+environment.keyPortal;
    return this.httpClient.post<any>(environment.apiUrl+'leggiHome',body, {headers:this.headers});

  }

  inviaRichiesta(nomeCliente:string,telefonoCliente:string,emailCliente:string,noteCliente:string){
    let body = 'nomeCliente='+nomeCliente+'&telefonoCliente='+telefonoCliente+'&emailCliente='+emailCliente+'&noteCliente='+noteCliente+'';
    return this.httpClient.post<any>(environment.apiUrl+'inviaRichiestaInfo',body, {headers:this.headers});

  }

}
