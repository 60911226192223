export class News{
  codice:number = 0;
  titolo: string = '';
  descrizione: any = '';
  shortdescrizione: any = '';
  data: string = '';
  urlfoto:string = '';
  fotoext:string = '';
  codicecategoria:number=0;
  categoria:string='';
}
