import { Noleggio } from './../models/noleggio';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NoleggioService } from '../services/noleggio.service';

@Component({
  selector: 'app-detail-noleggio',
  templateUrl: './detail-noleggio.component.html',
  styleUrls: ['./detail-noleggio.component.css']
})
export class DetailNoleggioComponent implements OnInit {

  richiestaNoleggio:Noleggio;
  telefono:string;
  cliente:string;
  email:string;
  note:string;

  esitoRichiesta:number =0;   // 0=niente 1=OK  2=KO

  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private noleggioService: NoleggioService, private translateService:TranslateService) {
    if(this.noleggioService.getLocalCart("cart_noleggio")){
      this.richiestaNoleggio=JSON.parse(this.noleggioService.getLocalCart("cart_noleggio"));
    }
  }

  ngOnInit(): void {
    this.esitoRichiesta=0;
  }

  inviaRichiesta(){
    this.esitoRichiesta=0;
    this.noleggioService.inviaRichiesta(''+this.richiestaNoleggio.numeroPreventivo,this.cliente,this.telefono,this.email,this.note).subscribe(
      resp => {
        this.esitoRichiesta=resp.response && resp.response==='ok'?1:2;
      },
      error => {
        this.esitoRichiesta=2;
      }
    )
  }
}
