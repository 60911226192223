
  <!-- Content
  ============================================= -->
  <div id="content">
    <!-- 404
    ============================================= -->
    <section class="section">
      <div class="container text-center">
        <h2 class="text-25 font-weight-600 mb-0">Logout</h2>
        <h3 class="text-6 font-weight-600 mb-3">Sei uscito correttamente dalla tua Area Personale</h3>
        <a [routerLink]="['/home']" class="btn btn-primary shadow-none px-5 m-2">Home</a></div>
    </section>
    <!-- 404 end -->

  </div><!-- Content end -->
