import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsHelperService {

  constructor() { }

  static getLocaleDateString(lang) {
    const formats = {
      'it': 'dd-LL-yyyy',
      'en': 'yyyy-LL-dd'
    };

    return formats[lang] || 'dd-LL-yyyy';
  }

  static getLocaleDateStringToShow(lang) {
    return UtilsHelperService.getLocaleDateString(lang).replace(/L/g, 'M');
  }

  static convertMsToDayHourMinuteSecond(ms:number) {
		let SECOND = 1000;
    let MINUTE = 60 * SECOND;
    let HOUR = 60 * MINUTE;
    let DAY = 24 * HOUR;

    let text = '';
		if (ms > 0 && ms< MINUTE) {
			return "";
		}
		if (ms > DAY) {
			text+=(ms / DAY)+'g ';
			ms %= DAY;
		}
		if (ms > HOUR) {
			text+=(ms / HOUR)+'d ';
			ms %= HOUR;
		}
		if (ms > MINUTE) {
			text+=(ms / MINUTE)+'m ';
			ms %= MINUTE;
		}
		return text;
	}

}
