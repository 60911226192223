import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookiepage',
  templateUrl: './cookiepage.component.html',
  styleUrls: ['./cookiepage.component.css']
})
export class CookiepageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
