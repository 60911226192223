import { Component, OnInit } from '@angular/core';
import { NewsTutto } from '../models/newstutto';
import { News } from '../models/news';
import { NewsCategorie } from '../models/newscategorie';
import { NewsRecenti } from '../models/newsrecenti';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PagecontentService } from '../services/pagecontent.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-novita-single',
  templateUrl: './novita-single.component.html',
  styleUrls: ['./novita-single.component.css']
})
export class NovitaSingleComponent implements OnInit {
  idNews:number = 0;
  infonews:NewsTutto = new NewsTutto();
  listaNewsCategorie:NewsCategorie[];
  listaNewsRecenti:NewsRecenti[];
  listImage65x65: any[];
  selectedNews:News = new News();
  filtroDescrizione: string = "";
  selectedCategoria:number =0;


  constructor(private router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,private pageService: PagecontentService, public authService: AuthService, private translateService:TranslateService,private sanitizer: DomSanitizer) {
    this.idNews=parseInt(this.route.snapshot.queryParamMap.get('idNews'));
   }

  ngOnInit(): void {
    this.selectedCategoria = 0;
    this.filtroDescrizione = "";
    this.refreshNovita(2);
    this.selectedNews=new News();
  }

  onRefresh(){
    this.filtroDescrizione;
    this.router.navigate(['novita'],{state: {filtroDescrizione: this.filtroDescrizione}});
  }


  onRefreshCategoria(selected: number){
    this.router.navigate(['novita'],{state: {selectedCategoria: selected}});
  }

  //per NEWS
  refreshNovita(leggitutto: number){
    this.spinner.show();
    this.pageService.getNews(leggitutto,this.filtroDescrizione,this.selectedCategoria,this.idNews).subscribe(
      response => {
        this.spinner.hide();
        this.infonews = response;
        if (this.infonews.listnews.length>0){
          this.selectedNews = this.infonews.listnews[0];
          this.selectedNews.descrizione = this.sanitizer.bypassSecurityTrustHtml(this.selectedNews.descrizione);
        }
        this.listaNewsCategorie =this.infonews.listnewscategorie;
        this.listaNewsRecenti = this.infonews.listnewsrecenti;
        this.listImage65x65 = [];
        this.getUrlPiccole();
       },
      error =>{
        this.spinner.hide();
        console.log(error);
      }

    )
    }

    getUrlPiccole() {
      let index :number;
      let newurl :string;
      for(let i=0;i<this.listaNewsRecenti.length;i++){
        index = this.listaNewsRecenti[i].urlfoto.lastIndexOf(".");
        newurl =  this.listaNewsRecenti[i].urlfoto.substring(0,index);
        newurl+= "P." + this.listaNewsRecenti[i].fotoext;
        this.listImage65x65[i] = newurl;
        }
    }


    onSelect(selected: News): void {
      this.idNews = selected.codice;
      this.refreshNovita(2);
    }

}
